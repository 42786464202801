
export const ReleaseNoteData = [
    {
        header: 'TAP Webv8 (8.00.35) September 29th, 2024',
        content: [		
            { text: `Enhancement - <font color = "#007979"> Inspection - Systems </font> - Added XML Upload date, Software Version and Revision in System List` },
            { text: `<b>Enhancement - <font color = "#007979"> Dashboard - Failed Equipment list </font></b>
                <br/>&nbsp;- Added New columns :Tested Date, Notification Number, Comment, Inspection Task Status, Lead Tech Name, Lead Tech Email Address.
                <br/>&nbsp;- Removed existing Building and Floor columns.` },
            { text: `<b>Enhancement - <font color = "#007979"> Inspection - Add Inspection Task </font></b>
                <br/>&nbsp;- System dropdown list is changed to radio button list.
                <br/>&nbsp;- Active and Closed Systems will be listed in the system selection section.
                <br/>&nbsp;- Closed System will be shown as red link and can be activated by clicking on it.` },
            { text: `Enhancement - Replaced '#Name' in Inspection reports with a blank space.` },	
            { text: `Enhancement - <font color = "#007979"> Inspection - Reports </font> -  Added a feature for maximizing at the top right corner of the report viewer window.` },
            { text: `Enhancement - <font color = "#007979"> Inspection - Generate Reports </font> - Added Table Border Controls to the Table Definitions to reflect in generated Inspection Reports.` }, 
            { text: `Enhancement - Added Table Border Controls to the Table Definition file to reflect in Inspection Reports.` },			
            { text: `Enhancement - Added a feature to post important information on TAP Web login screen. This feature is available for Super Administrators.` },
            { text: `Bug Fix - <font color = "#007979"> Equipment Edit - </font> Fixed an issue while pressing the carriage return key appends a newline character in text fields, which also reflects in tap reports.`  },	
            { text: `Bug Fix - Fixed an issue where inspection task becomes uneditable after converting the associated lead technician's user role.` },		
            { text: `Temporarily retracted  the feature for auto closing systems having no test Activity for more than 18 months.` },      					     
        ]
    },
    {	
		header: 'TAP Pro (V8.00b30) - September 29th, 2024',
		content: [
            { text: `Enhancement - Added a feature to show images for already added failed equipment.` },
			{ text: `Bug Fix - Fixed an issue where pressing the carriage return key appended a newline character in text fields, which was reflected in tap reports.`  },			
        ]
	},
    {
        header: 'TAP Webv8 (8.00.34) Aug 5th, 2024',
        content: [		
            { text: `Bug Fix - <font color = "#007979"> Log Book </font> - Fixed an issue where columns are empty for activities related to inspection task life cycle.` },
                
        ]   
	},
    {
    header: 'TAP Webv8 (8.00.33) July 1st, 2024',
    content: [
        { text: `Bug Fix - <font color = "#007979"> Manage - Attributes - Equipment Type attribute values </font> - Fixed an issue where data is not saving properly when beginning with numbers.` },
        { text: `Bug Fix - <font color = "#007979"> Users - User Login - </font> Fixed an issue where unsuccessful Logins are being saved as Last Login data.`},		
        { text: `Bug Fix - <font color = "#007979"> Inspections - Inspection Report Generation  </font> - Fixed an issue where Carriage return in equipment edit for common attribute text area fields are not reflecting in Inspection Reports.` },
        { text: `Bug Fix - <font color = "#007979"> Inspection Reports - Inspection Report Generation  - </font>  Added pictures to Sprinkler Tables in TAP Web.`},
    ]
    },
    {
		header: 'TAP Webv8 (8.00.32) June 20th, 2024',
		content: [
            { text: `Hotfix - <font color = "#007979"> Inspections -  Inspection Details -  Multiple Edit - </font> Fixed an issue where multiple edit is not working when there are no NFPA Classifications mapped.` },
            { text: `Hotfix - <font color = "#007979"> Administration - Sites - Floor List - </font>  Fixed an issue where floor list is not loading properly.` },
        ]
	},
    {
		header: 'TAP Webv8 (8.00.29) June 8th, 2024',
		content: [
            { text: `Enhancement - <font color = "#007979"> Inspection Settings - Inspection Fields - Questions - Delete Nodes - </font> Mapped reports to a question node will be shown when deleting a question node.` },
            { text: `Enhancement - <font color = "#007979"> Reports - Life Safety Systems Report -  </font>  Now Admin-Tech users can also view and manage Life Safety Systems report.` },
            { text: `Enhancement - <font color = "#007979"> Reports - Life Safety Systems Report -  </font>  Changed all dates to mm.dd.yyyy and mm.yyyy formats.` },
            { text: `Enhancement - <font color = "#007979"> Reports - Life Safety Systems Report -  </font>  Changed the current cover page and Siemens logo.` },
            { text: `Enhancement - <font color = "#007979"> Reports - Life Safety Systems Report -  </font>  Removed Top 10 Sensitivity Table.` },
            { text: `Enhancement - <font color = "#007979"> Inspections -  Inspection Details -  Equipment List -</font> Now the last viewed Equipment type will be saved for each user.` },		
            { text: `Enhancement - <font color = "#007979"> Dashboard </font> Enabled Key Performance Indicator` },
            { text: `Enhancement - <font color = "#007979"> Inspection - Generate Reports -</font> Failed equipment images will be shown in Inspection reports.` },
            { text: `Enhancement - <font color = "#007979"> Inspection - Generate Reports -  </font>  Colorized 'PASS' and 'FAIL' with green and red in Inspection reports.` },
            { text: `Enhancement - <font color = "#007979"> Reports - TAP Performance Report -  </font>  Changed name from "TAP Performance Report" to "Life Safety Systems Report.` },
            { text: `Enhancement - <font color = "#007979"> Plugins - </font> Removed Send Mail link from TAP Pro section.` },
            { text: `Enhancement - Implemented Detailed log for inspection report generation.`},
            { text: `Bug Fix - Fixed an issue where Inspection and System shows in wrong site when user works on multiple tabs.`},
        ]
	},
    {	
		header: 'TAP Pro (V8.00b29) - June 8th, 2024',
		content: [
            { text: `Enhancement - <font color = "#007979"> Equipment Edit - </font> Implemented uploading of failed equipment images during inspection.` },
			{ text: `Bug Fix - <font color = "#007979"> Equipment Edit - </font> Fixed an issue where slashes appear for strings with double quotes.`  },						
        ]
	},
	{
    header: 'TAP Webv8 (8.00.30) April 1st, 2024',
		content: [
            { text: `Enhancement - <font color = "#007979"> Plugins - </font> Removed QR Code for Admin and Branch users.` },
            { text: `Enhancement - <font color = "#007979">AsBuilts – Drawings </font> - Implemented a feature to upload PDF drawings.` },
            { text: `Bug Fix - <font color = "#007979"> Inspection - Inspection Details - Equipment edit - </font> Fixed an issue where equipments getting carry forwarded data of the unmapped attributes when using previous/next button.`},      
		    { text: `Bug Fix - <font color = "#007979">Inspection Tasks </font> - Resolved an issue where wrong system was listed in the Add Inspection Task feature after searching for a customer using Global search.` },
            { text: `Bug Fix - <font color = "#007979">Inspection Tasks - Inspection Details</font> Fixed an issue where the path for equipment attachments was not found, resulting in attachments not being visible.` },
		]
	}, 
	{
		
		header: 'TAP Pro (V8.00b20) - April 1st, 2024',
		content: [
			{ text: `Enhancement - Improved TAP Pro equipment list view to display more text. Stacked the Pass/Fail icon and Note icon instead of having them side-by-side.` },
			{ text: `Enhancement - Selected item styles have been changed to bold in equipment edits to improve visual clarity during searches.`  },			
			{ text: `Enhancement - Blocked the characters (µ and §) in Inspection Details - Panels, System, Contract, and Equipment add/edit.` },
			{ text: `Enhancement - Now able to view drawing as PDF from the drawing list.` },			
			{ text: `Bug Fix - Inspection details - Questions - Question fields has been ordered.` },
		]
	},	
	
	{
		header: 'TAP Webv8 (8.00.29) February 26th, 2024',
		content: [
            { text: `TAP Web - <font color = "#007979">Inspection Details>Questions </font> - Enabled the option for Super Administrator to remove unused Questions.` },
            { text: `TAP Web - <font color = "#007979">Inspection Tasks List </font> -  Added a new column that shows the percentage of equipment which has Pass/Fail results.` },
            { text: `<font color = "#007979">TAP System </font>- Automated Task Reminder Emails - Added the Site Number along with Task Name to help locate the Task.` },
            { text: `TAP Web - <font color = "#007979">Inspection>Systems - Upload Config File </font> - Allowed .7z extension when uploading configuration file.` },
			{ text: `TAP Pro - Fixed an issue where newly added Question Groups are not displayed in the TAP Pro app` },
        ]
    },
    
  
    ///last production update/////
    {
		header: 'TAP Webv8 (8.00.28) February 13th, 2024',
		content: [
            { text: `Hot Fix - <font color = "#007979">Inspection - Systems - </font> - Fixed an issue where System Name Edit feature not working properly.` }
		]
	},
    {
		header: 'TAP Webv8 (8.00.27) February 5th, 2024',
		content: [
            { text: `Enhancement - <font color = "#007979">Inspection Reports - Reports </font> - Access to view and download draft reports is now limited to Super Admins and Support Users only. This change is applicable only in the US.` },
            { text: `Enhancement - <font color = "#007979">TAP Performance Report </font> - Added a new feature to Clone Project` },
            { text: `Enhancement - <font color = "#007979">Inspection Tasks - Inspection Details </font>- System now allows to enter non-keyboard characters except 'µ' and '§'. A Validation message will be shown when entering these two non-keyboard characters (µ and §).` },
            { text: `Enhancement - <font color = "#007979">Inspection - Systems -  Upload Config File </font> - Removed the restriction of uploading configuration file when an active task exist for the system.` },
		]
	},
    {
		header: 'TAP Webv8 (8.00.26) January 25th, 2024',
		content: [
            { text: `Enhancement - Allowed Tech, Admin, and Admin Tech to reactivate the most recent Task of the system.` },
			{ text: `<b>TAP Performance Report - Template changes & Table date changes</b>
                </br>- Avoided floating away of titles when tables are populating. 
                </br>- Added "Dry and Pre-Action Systems" to the title in Fire Sprinkler Flow Test. 
                </br>- Changed Test Due Date to "Due Date" in Fire Sprinkler Flow Test.
                </br>- Changed Test Due Date to "Due Date" in Sprinkler Air Leak Test(Dry and Pre-action Systems). 
                </br>- Changed Inspection Due Date to "Due Date" in Sprinkler 5 Year Internal Inspection.
                </br>- Changed "Gauge Replacement or Testing Due Date" to "Due Date" in Fire Sprinkler Gauges.
                </br>- Changed Inspection Due Date to "Due Date" in Standpipe 5 Year Full Flow Test (Only for Automatic Wet, &nbsp;&nbsp;&nbsp;Combined, Dry and Semi-automatic Dry).
                </br>- Changed Inspection Due Date to "Due Date" in Standpipe Hydrostatic Test (Only for Manual Dry, Wet, and &nbsp;&nbsp;&nbsp;Semi-automatic).
                </br>- Changed "Gauge Replacement or Testing Due Date" to "Due Date" in Standpipe Gauges.
                </br>- Changed "Gauge Replacement or Testing Due Date" to "Due Date" in Clean Agent 5 Year External Inspection.                
                </br>- Changed Hydro Static Due date to "Due Date" in Kitchen Hood Suppression System Hydrostatic Test.
                </br>- Change Last Internal Inspection Date to "Last Internal Inspection Date" in Portable Fire Extinguisher Internal &nbsp;&nbsp;&nbsp;and Hydro test.` },
            { text: `Enhancement - <font color = "#007979"> Inspection Tasks - More - Compare  </font> : Added Export All and Export Highlighted options.`},
            { text: `Enhancement - Implemented automated reminder emails for Active and Closed tasks. `},
			{ text: `Enhancement - Special Reports for Sales Opportunity -> Implemented model filter list in such a way that the selected item list in top and all other equipment's of same system below. `},
           	{ text: `Bug Fix - <font color = "#007979">Inspection Reports </font> – Resolved the issue of Large report files failed to convert Word to PDF.`},
            { text: `Bug Fix - <font color = "#007979">Inspection - Inspection Reports </font> – Resolved Battery Manufacture Date shifting one day issue .`},	
			{ text: `Bug Fix - <font color = "#007979">Inspections - Inspection Tasks - Add Inspection Task</font> – Resolved the issue of adding two active tasks in the same system.`},	

		]
	},
	{
		
		header: 'TAP Pro (V8.00b19) - January 25th, 2024',
		content: [
			{ text: `Bug Fix - Fixed an issue with task joining trouble in large systems.` },
			{ text: `Bug Fix - Fixed an issue where special characters were not able to enter on iOS (Canada).`  },			
			{ text: `Bug Fix - Fixed an issue in TAP Pro where report generation was displaying the message 'Report Generation Failed.` },	
			{ text: `Bug Fix - Fixed an issue where the Final Reports page was not refreshing and not showing recently generated reports.` },
		]
	},
    {
		
		header: 'TAP Pro (V8.00b16) - December 20th, 2023',
		content: [
			{ text: `Fixed an issue where the date selector was not showing for long questions in TAP Pro on the Inspection Details: Questions page. ` },			
			{ text: `Improved the loading time for main nodes and sub-nodes on the Equipment List page in TAP Pro.` },
			{ text: `Provided a new feature to view the Markup list in the Drawing list page and User can open Markups by selecting the required Markups check box and then clicking the 'Show in Drawing' button.` },			
			{ text: `Provided a new message, 'This user account is not active,' when inactive, deleted, blocked, or locked users attempt to log in to TAP Pro. Previously, the message was 'Invalid User Name or Password’.` },
			{ text: `‘Edit Comment' option is disabled for Rectangle and Cloud markups because there is no option to add comments in Rectangle and Cloud Markups.` },					
			{ text: `Bug Fix - Fixed an issue where the newly added Date Time picker field (in Web), displayed as a Text Field in TAP PRO, had been changed to a Date Time picker on the Inspection Details page. ` },
			{ text: `Bug Fix - TAP Pro previously allowed users to log in without changing their password, even if the user had checked the 'Change Password on next login' option in Edit User on TAP Web. Now, this action is blocked by showing a message - 'Password has to be changed at this login. Please change your password from Desktop’. ` },			
			{ text: `Bug Fix - Fixed an issue where the options 'Highlighted' and 'Text Size' in Callout and Text Markups were not correctly placed (UI issue) on the Markup Add/Edit page in the Drawing. ` },				
		]
	},
    {
		header: 'TAP Webv8 (8.00.25) December 20th, 2023',
		content: [
            { text: `Enhancement - <font color = "#007979"> Administration - Site - Buildings </font>  : Entering comma character in building name is now blocked. The comma is causing report generation issues.` },
            { text: `Enhancement - <font color = "#007979"> Inspection - Inspection Reports - Generate Reports </font> : System Dynamic fields will reflect in Generated reports`},
            { text: `Enhancement - <font color = "#007979"> Manage - Inspection Settings - Inspection Fields - Questions </font> - Implemented facility to change field order. This will allow me to rearrange the Questions so relevant question can be located near each other.`},
           	{ text: `Bug Fix - Fixed an issue where XNET and HNET connection method choices are not be reflected for XLS and Desigo Modular systems.`},
            { text: `Bug Fix -  Corrected tested date and dev reading date to match with time zone of the site.`},	

		]
	},
    {
		
		header: 'TAP Webv8 (8.00.24) December 01st, 2023',
		content: [
            { text: `Enhancement - <font color = "#007979"> Plugins </font> -  Removed TAP APP section.`},
            { text: `Enhancement - <font color = "#007979">Inspections Tasks -  Inspection Details -  Equipment List </font> - Moved 'Edited By' column to far end of the grid.`},
            { text: `Enhancement - <font color = "#007979">Inspection Tasks - Inspection Details - Equipment List </font> - Added a Save Button in all Date Time Pickers.`},
            { text: `Enhancement - <font color = "#007979">Inspections Reports </font> - Implemented Universal Date Format for generated Inspection Reports.`},
            { text: `Enhancement - <font color = "#007979"> Inspection Activity Reports </font> - Renamed "Sheet 1" to "Sheet1" in excel export.`},
            { text: `Enhancement - <font color = "#007979"> Inspections - Inspection Tasks - More - Compare - </font> - Removed irrelevant details from the Excel export.`},
            { text: `Enhancement - <font color = "#007979"> Inspection Tasks - Inspection Fields - Questions </font> - Removed unused Canada Testing Question nodes.`},   
            { text: `Enhancement - <font color = "#007979"> Inspection Tasks - Inspection Fields - System </font> - Static system fields are also listed along with dynamic fields.`},  
            { text: `Added a new feature Sales Activity Report in <font color = "#007979">Reports</font> page. This feature will be available for Super Administrators, Support, Admin and Admin-Tech.` },          
            { text: `Bug Fix - <font color = "#007979"> Inspection Activity Reports </font> - Inspection Tasks - Inspection Fields - Questions - Fixed an issue where 250 character allocation for Question nodes was not working.`},            

		]
	},
    {
		
		header: 'TAP Webv8 (8.00.23) November 13th, 2023',
		content: [
            { text: `Enhancement - <font color = "#007979">Inspections Tasks -  Inspection Details -  Equipment List </font> - Added 'Edited By' column to denote who edited an equipment`},
            { text: `Enhancement - <font color = "#007979">Inspection Tasks - Inspection Details - Equipment List </font> - Added a Save Button in Date Time Picker for Test Date and Dev Reading Date`},
            { text: `Enhancement - <font color = "#007979"> Plugins </font> -  Added Android and IOS QR Code Buttons for TAP Pro`},		
		]
	},
    {
		
		header: 'TAP Pro (V8.00b16) - November 10th, 2023',
		content: [		
			{ text: `Introduced a Change Color feature in the Markups List page.` },
			{ text: `Introduced a Change Color feature while creating Markups from the Drawing page.` },			
			{ text: `Introduced Highlight button and Change Text Size features for Text Markups on the Drawings page.` },			
			{ text: `Introduced Change Text Size feature for Callout Markups on the Drawings page.` },
			{ text: `Introduced the multiple Equipment selection in 'Assign block' feature on the Drawings page.` },			
			{ text: `Changed the background of the Panel Display screen to improve readability (removed overlays) while using the keyboard.` },		
            { text: `Introduced Add, Edit, Delete, Move and Save features of Text Markups in Drawings page.` },			
			{ text: `Introduced Add, Edit, Delete, Move and Save features of Callout Markups in Drawing page.` },
			{ text: `New confirmation message is introduced for unsaved Markups in the Drawing page.` },		
            { text: `Introduced a message/ toast message that is displayed when a technician selects the Read-Only mode (Equipment View Online).` },			
			{ text: `A new confirmation message is introduced, which appears when the user exits from the Inspection Details, Equipment List or Panel View page.` },			
			{ text: `Bug Fix - Fixed an issue where filtering by untested devices caused the device list /modules to rearrange out of sequence on the Equipment List: Filter page.` },
			{ text: `Bug Fix - Fixed an issue where the TAP Pro MXL Keyboard was not working.` },		
            { text: `Bug Fix - Fixed cursor positioning issue with the Panel Display screen while pressing keys.` }				
		]
	},
    {
		
		header: 'TAP Pro (V8.00b15) - Oct 31st, 2023',
		content: [
			{ text: `Introduced Add, Edit, Delete, Move and Save features of Text Markups in Drawings page.` },			
			{ text: `Introduced Add, Edit, Delete, Move and Save features of Callout Markups in Drawing page.` },
			{ text: `New confirmation message is introduced for unsaved Markups in the Drawing page.` },	
            { text: `Introduced a message/ toast message that is displayed when a technician selects the Read-Only mode (Equipment View Online).`},
            { text: `A new confirmation message is introduced, which appears when the user exits from the Inspection Details, Equipment List or Panel View page.`},
            { text: `Bug Fix - Fixed an issue where filtering by untested devices caused the device list /modules to rearrange out of sequence on the Equipment List: Filter page.`},
            { text: `Bug Fix - Fixed an issue where the TAP Pro MXL Keyboard was not working.`},					
		]
	},
    {
        header: 'TAP Webv8 (8.00.22) Nov 09th, 2023',		
        content: [
			{ text: `Enhancement - Allowed 250 characters for Node name and allowed 1000 characters for Inspection Question field name.` },	
			{ text: `Revised the calculation for the "Days to Upload" column for each individual report. ` },			
			{ text: `Bug Fix - Resolved the issue of losing focus when entering values in the Question fields after validation.` }
		]
	},
    {
		
		header: 'TAP Webv8 (8.00.21) Oct 30th, 2023',
		content: [
			{ text: `Framework conversion for Report Generator.` },
			{ text: `Resolved the DLL compatibility issues with Report Generator Project` },						
			{ text: `Bug Fix - Fixed the issue of not showing 'Description of property' in Inspection Reports.` },
            { text: `Bug Fix - Multi-edit of the Test and device reading dates.` },
			{ text: `Bug Fix - Inline edit of the Test and device reading dates.` }
		]
	},
    {
    header: 'TAP Pro V8(V8.00b14) - Oct 1st, 2023',
		content: [
           { text: `New TAP Pro Application is available for use on both iOS and Android mobile operating devices.
           </br><b>Key Features of TAP Pro </b>`  },
           { text: `Time saving mobile application for:
           </br>- In conjunction with TAP Bridge, users can remotely view and control Siemens systems during system certifications, inspections, or service calls.
           </br>- Entering Notes, Comments, Model numbers, and other data, per equipment as you perform the test.
           </br>- Recording Pass/Fail information.
           </br>- Capturing pictures of issues found.
           </br>- Utilizing Barcode technology to locate equipment in the TAP database.
           </br>- Adding or removing equipment from the master equipment data.
           </br>- Viewing the As-built CAD files.
           </br> - Marking As Built CAD drawings with update notations.
           </br>- Generate and View Reports.
           </br>- Sign Report
           </br><b>New Features of TAP Pro</b>` },
			{ text: `Implemented Recent Tasks tab in the Inspection Tasks page for viewing the recent tasks of the logged in Technician.` },						
			{ text: `Provided option to update the Contract information of the Inspection Task.` },
            { text: `Real Time Synchronization of the Equipment Updates to the Server.` },	
            { text: `Implemented the saving of Default Values in the Questions tab, without clicking Save button.` },
			{ text: `Implemented new User Friendly UI and provided Performance Improvement.` },
			
		]
	},
    {
		
		header: 'TAP Webv8 (8.00.20) Oct 01st, 2023',
		content: [
			{ text: `Enhancement - Inspection : Inspection Tasks : Inspection Details - Changed 'Tested Date' and the 'Dev Reading Date' as editable. Defaulted to current Date & Time. 'Tested Date' and 'Dev Reading Date' will be displayed in red text if the default Date & Time was not used.` },
			{ text: `Enhancement - Changed the Upload to Service Portal to only happen if the Inspection Task is set to Completed.` },						
			{ text: `Enhancement - Changed the Inspection Task Completion status update to require that Insepction Final Report be in place.` },
			{ text: `Added the feature where the user should upload the Final Report before completing the Inspection Task.` },
            { text: `Bug Fix - Inspection : Inspection Tasks : Drawings - Fixed an issue where display settings (show in drawing) are not reflecting in Drawings.` }
		]
	},
    {
		
		header: 'TAP Webv8 (8.00.19) August 25th, 2023',
		content: [
			{ text: `Inspection Tasks - Added a yellow icon against System Column if notes exist for the system.` },
			{ text: `Inspection- Inspection Reports - Delete - Report deleting privileges Modified.` },						
			{ text: `Added new Non-Siemens System "DMP".` },
			{ text: `Changed default start date and end date to current date when adding an Inspection Task.` },
			{ text: `Inspection Activity Report - Fixed an issue where Equipment Failure Column was missing in export excel.` },
            { text: `Bug Fix - Inspection Fields - Questions - Fixed an issue While Selecting Date - shown date is one Day Before the Selected Date in CST Time zone.` },			
			{ text: `Bug Fix - Fixed an issue where system move incorrectly shows all the systems of every Site linked to the Customer.` },
            { text: `Bug Fix - Inspection Tasks - Drawings - Fixed an issue where display settings (show in drawing) are not reflecting in Drawings.` },
		]
	},
	{
		
        header: 'TAP Webv8 (8.00.18) August 8th, 2023',
		content: [
			{ text: `Added the feature to auto close systems having no test Activity for more than 18 months.` },			
            { text: `Inspection - Inspection Tasks - Inspection Details - Questions - Implemented Special Character validation for Date Time Textbox.` },
			{ text: `Added Search Address option in Drawings- Common and Equipment Specific.` },			
			{ text: `Added a column to far right of the Inspection Activity Report to indicate the Task has failed equipment.` },			
			{ text: `Manage - Inspection Settings - Inspection Fields - System - Implemented facility to change Field Order.` },
			{ text: `Inspection Tasks - Add/Edit Inspection Tasks - Reports Selection - Fixed Field Order not reflecting issue.` },
			{ text: `Plugins - Changed TAP App Pro to TAP Pro.` },
			{ text: `Drawings - Fixed the issue of searching address instead of text search.` }
		]
	},	
	{
		
		header: 'TAP Webv8 (8.00.17) July 28th, 2023',
		content: [
			
			{ text: `Bug Fix - Fixed an issue in excel import where Test date value not importing.` },	
			{ text: `Bug Fix - Dataset Name and DataField Name entries switching positions when clicking on Save Changes.` },
			{ text: `Bug Fix - Fixed an issue where equipment could not be added when a non-attached equipment of same address exists.` },	
			{ text: `Bug Fix - Fixed an issue where Location field getting default value inside add Equipmnent in Inspection Details.` }	


		]
	},
	{
		
		header: 'TAP Webv8 (8.00.16) July 21st, 2023',
		content: [
			
			{ text: `Bug Fix - Fixed Excel Import Issue reported from production (Index out of bound in array message is popping up when trying to import excel files with blank fields.` }			
		]
	},
	{
		
		header: 'TAP Webv8 (8.00.15) July 20th, 2023',
		content: [
			{ text: `Enhancement - Excel Import - Error message for Non-Importable characters will now show the Cell location details.` },
			{ text: `Enhancement - TAP US only - Added new site pattern - 44OC-7digits.` },
			{ text: `Enhancement - Compare report will now show changes in 'Address' field.` },
			{ text: `Enhancement - Compare report will now show status as 'Edited' for changes in 'Address'.'NFPAClassification', 'Health Classification' or 'Location'.` },
			{ text: `Enhancement - Added a new System called "Doors" for door only system inspections.` },
			{ text: `Enhancement - Added a new Plugins section to TAP Web to share TAP Pro.  This is the future replacement of TAP App and is being tested by field users.` },
			{ text: `Enhancement - Improved timeout for report generation of larger reports.` },
			{ text: `Bug Fix - Fixed an issue where the date is not correctly getting selected when using the Date Picker feature.` },
			{ text: `Bug Fix - Inspection Details - Fixed an issue where System Details are not getting saved when the fields are not mapped in the selected reports.` },
			{ text: `Bug Fix - Fixed an issue where bulk equipment editing was not working.` },
			{ text: `Bug Fix - Fixed an issue where Internal notes were not saved when entering many characters.` },
			{ text: `Bug Fix - Fixed an issue where in most sections Start Date and End Date are not shown in Universal Date format in generated reports.` }			
		]
	},
	{
		
		header: 'TAP Webv8 (8.00.14) Jun 28th, 2023',
		content: [

			{ text: `Enabled viewing reports of closed systems.` },
			{ text: `Implemented a Universal Date Format (yyyy-mm-dd hh:mi:ss) across the system.` },
			{ text: `Fixed Inspection Task Refresh issue for newly added sites.` },
			{ text: `Changed name and numbers of closed contracts in 'Inspection Tasks' page to red and have provided a text indication on mouse hover.` },
			{ text: `Implemented a validation on uploading faulty xml files in the 'Report Config' page.` },
			{ text: `Added a 'Max Characters' field when adding a new inspection field of type 'Text Box' and 'Single Line Text' in 'Inspection Fields' page.` },
			{ text: `Added a mandatory 'Description of Property' field in 'Systems' tab of Inspection Fields.` },
			{ text: `Bug Fix - Fixed issue in Inspection Activity Report where 'Customer Number' column was wrongly populated with Customer Name for Admin, Admin-Tech, Tech and Branch users.` }		
		]

    },
	{

        header: 'TAP Webv8 (8.00.13) June 06th, 2023',

        content: [

            { text: `Added - New system types "Napco", "Faraday", "Mirtone", and "JCI".` },
            { text: `Added - New US Contract Number (44OC-1234567) Pattern for 44OC projects.` },
            { text: `Added - Red text is now shown below the Inspection Task name in the Generate Reports screen when the branch office needs to be selected.` },
            { text: `Added  - Manually set device test results will not be updated from TAP Bridge activity to preserve the manually set results.  I.e.: A manually Failed device will not be changed to Passed when the device accidentally retriggered.` },
            { text: `Enabled downloading multiple Draft and final inspection reports.` },
            { text: `Changed - The "Notes" field to "Internal Notes" to remind users that these field will not be on any reports and will only be visible by Siemens users.` },
            { text: `Bug Fix - Equipment type selection will be prompted when a user imports equipment while in the "All" view.` },
            { text: `Bug Fix - Data Field Name fields edit was not working in Questions Editor.  (For Super User only)` },
            { text: `Bug Fix - Report Mappings was wipeout settings.  (For Super User only)` },
            { text: `Bug Fix - Inspection Task List refresh issue when switching between sites and then contracts.` },
            { text: `Bug Fix - Default Values in the Question screens are now saved even without clicking save button.`}

        ]

    },
	{
		header: 'TAP Webv8 (8.00.12) May 16th, 2023',
		content: [			
			{ text: `Added Copy/Move Logs feature.`},
			{ text: `Added a last login date column in the Users screen after the Status column.`},	
			{ text: `Editing Questions is now disabled in Closed and Completed Task.`},
			{ text: `Bug Fix - Fixed the issue where default values would not populate for some question nodes.`},
			{ text: `Bug Fix - Fixed the issue where some users could not use the Global Search feature.`}				
				
		]
	},
	{
		header: 'TAP Webv8 (8.00.11) April 17th, 2023',
		content: [			
			{ text: `Bug Fix - Fixed the issue of download not working in Inspection Report Mapping.`},
			{ text: `Bug Fix - Fixed the issue of filter not holding when switching to other groups in Inspection Report Mapping.`},
			{ text: `Bug Fix - Fixed the issue of the whole grid list showing blank while sorting the rec column in Inspection Detail.`},
			{ text: `Bug Fix - Fixed the issue of report mapping going blank after changing the Report Group.`},
			{ text: `Bug Fix - Fixed the issue of unable to see the attachment photos in Equipment list.`},			
			{ text: `Bug Fix - Fixed an issue where Inspection Tasks displaying in wrong site when switching a site after using Inspection Task Global Search feature.`},
			{ text: `Bug Fix - Fixed an issue where System XML File uploaded status is wrongly displaying in System List.`},
			{ text: `Bug Fix - Fixed contract search issue not displaying contracts from all linked sites.`},
			{ text: `Bug Fix - Fixed the issue of long delay in closed and completed task.`},
			{ text: `Bug Fix - Fixed the issue of not opening CAD file of large size `}		
		]
	},
	{
		header: 'TAP Webv8 (8.00.10) March 29th, 2023',
		content: [			
			{ text: `TAPV8- Help : Changed the Yammer link for CA`},
			{ text: `TAPV8- Canada : Added Canada Flag in Logo`},
			{ text: `TAP V8 now allows uploading of attachments in multiple sites, buildings and floors.`},
			{ text: `Detailed application log added for inspection life cycle.`},
			{ text: `<font color = "#007979">Dashboard:</font> Fixed an issue where single row cannot be selected in failed equipment list.`},			
			{ text: `<font color = "#007979">Inspection Details:</font>Allowed 4000 characters for System Notes.`},
			{ text: `Bug Fix - Fixed an issue with contract search showing lots of duplicates results when logged in as a Tech user.`},
			{ text: `Bug Fix - Fixed an issue where inspection task grid becomes empty on-site switch.`},
			{ text: `Bug Fix - Fixed an issue of report not displaying when trying to view large reports.`},
			{ text: `Bug Fix - Fixed an issue where reports downloaded with the name "ActiveReports Document".`},
			{ text: `Bug Fix - Fixed the Download Name Issue in Draft and Final reports, while trying to save the Report from pdf viewer in browser.`},			
		]
	},

	{
	header: 'TAP Webv8 (8.00.09) March 13th, 2023',
		content: [			
			
			{ text: `Introduced a new feature to purge unused reports.`},
			{ text: `Added Progress indication for Download Plugins, Also resolved "File Not Found" error`},
			{ text: `Reports - Export to Excel is now working.`},
			{ text: `Inline equipment edit feature is now working correctly.`},
			{ text: `<font color = "#007979">SAP Import Status </font>- Implemented pagination and sorting.`},
			{ text: `Fixed an issue of loading blank page when adding 1st device in <font color = "#007979">Inspection Details : Equipment</font>.`},
			{ text: `Bug Fix - Calendar selector is now smaller to fit on smaller screens.`},
			{ text: `Bug Fix - Fixed default values not populating when creating new Task.`},
			{ text: `Bug Fix - Fixed export Equipment Data to the same formats required for importing(mm/dd/yyyy).`},			
			{ text: `Bug Fix - Equipment Type' column now hidden when Equipment type is not set to 'All'.`},			
			{ text: `Bug Fix - <font color = "#007979">Inspection Task:</font> Fixed the issue where on selecting multiple tasks and clicking equipment summary no validation message was displayed. `},
			{ text: `Bug Fix - <font color = "#007979">Administration: Sites: Building / Floors: Attachments: List:</font> (for Tech User) Fixed the issue Inspection Details: Attachments: Fixed the issue where 'Uploaded By' field value contains an extra comma (,).`},
			{ text: `Bug Fix - <font color = "#007979">Administration: Sites: Building / Floors: Attachments: List:</font> (for Tech User) Fixed the issue where multiple selection of list shouldn't be allowed.`},
			{ text: `Bug Fix - Fixed issue where equipment list exporting is missing Notes and Comments data when 'All' is selected for the Equipment Group.`},
			{ text: `Bug Fix - Import Equipment - Date time validation for Date type removed.`},			
			{ text: `Bug Fix - Fixed an issue when <font color = "#007979">Inspection Details - Equipment</font> Multiple Edit not getting saved  when 'apostrophe' symbol is used.`},
			{ text: `Bug Fix - Fixed an issue of page going blank when Editing Equipment from other pages in the grid.`},
			{ text: `Bug Fix - Fixed showing of duplicate results in Contract Global Search.`}

		]
	},
	{
		header: 'TAP App (7.00.09) February 13th, 2023',
		content: [

			
			{ text: `Reports assigned to a task after a tech joins, will now be refreshed on clicking 'Download Data' icon in either inspection system page or inspection panel page.`},
			{ text: `Bug Fix - Fixed data loss issue where not all the Inspection Questions server data is being sent to the TAP App when joining a Task.`},
			{ text: `Bug Fix - Fixed issue where apostrophe doubles when using the TAP App to enter text that contains apostrophe.
			
			</br><b><i>Note to Inspectors </i> - </b>This will be a mandatory update.`}
			
		]
	},

	{
		header: 'TAP Webv8 (8.00.08) January 29th, 2023',
		content: [
			{ text: `Bug Fix - Fixed the issue of not able to Restore deleted Users.`},
			{ text: `Bug Fix - Fixed the issue of showing error message on updating User for mapping Sales Offices.`},
			{ text: `Bug Fix - Fixed issue of non displaying of Panels and equipment when converting from XLS Panel to Desigo Modular.`},
			{ text: `Bug Fix - Fixed issue of disappearing (blacking out)of menu items in the Left pane.`},
			{ text: `Bug Fix - Fixed issue of Double Login when the Session expires.`},
			{ text: `Bug Fix - Fixed issue of not able to Reset the Password of restored Users`}
		]
	},

	{
		header: 'TAP App (7.00.08) January 20th, 2023',
		content: [
			
			{ text: `Bug Fix - Fixed data loss issue where not all the Inspection Questions server data is being sent to the TAP App when joining a Task.
			
			</br><b><i>Note to Inspectors </i> - </b>This will not be a mandatory update but is a very important update. For an inspector already joined in a Task, you should Quit the Task before installing the update, and then rejoin the Task.`}
			
		]
	},
	{
		header: 'TAP Webv8 (8.00.07) January 20th, 2023',
		content: [
			{ text: `Implemented Export All / Highlighted options in all grid pages. These options are available under the <font color = "#007979">Download</font> icon in the grid pages`},
			{ text: `As Builts module is updated with options to add, edit and Delete Drawing.`},
			{ text: `Implemented Drawing feature in <font color = "#007979">Inspection: Inspection Tasks: Drawings</font> page. `},
			{ text: `Bug Fix - Fixed issue of displaying Notification Number for Project Contract while adding a new Task from <font color = "#007979">Inspection: Inspection Tasks</font> page.`},
			{ text: `Bug Fix - Fixed issue of not working of Filter option while selecting values for multiple fields in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details</font> page.`},
			{ text: `Bug Fix - Fixed issue of not holding the equipment list filter when changing pages`},
			{ text: `Bug Fix - Fixed issue with move contract`},
			{ text: `Bug Fix - Fixed issue of non parsing of System when an updated XML file is uploaded against an already existing System with Tasks.`}
			
		]
	},

	{
		header: 'TAP Webv8 (8.00.06) January 10th, 2023',
		content: [
			{ text: `Implemented a new feature to show Equipment Summary as Pie chart in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Summary</font> page. This Pie chart shows the count of Equipment in different Test Status such as Passed, Failed and Untested.`},
			{ text: `Check box 'Send log file to mail while generating reports' is now available under <font color = "#007979">Inspection: Inspection Reports: Generate Reports </font> for Super Administrators. This option will send the log files to logged in User's Email.`},
			{ text: `A new progress indicator is shown when View Draft Report, Download Draft Report, View Final Report & Download Final Report buttons are clicked from the <font color = "#007979">Inspection: Inspection Reports: Reports</font> page.`},
			{ text: `All Report Group checkboxes are now arranged horizontally in the Report Selected page. Inspection Task Name and Lead Technician are also provided at the top of the 'Report Selected' page under <font color = "#007979">Inspection: Inspection Tasks: Report Selected</font> and <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Report Selected</font>.`},
			{ text: `Bug Fix - Removed “Preceding Branch Office Name” field from Branch Office Details in the Contract tab in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details </font>. `},
			{ text: `Increased the Session Timeout time for TAPWeb to 4 hours.`},
			{ text: `Bug Fix - Fixed issue of numeric values are not working correctly in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Equipment Add/Edit</font> page.`},
			{ text: `Bug Fix - Fixed issue of unable to edit Equipment of Type - Gaseous Extinguishing Systems in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Equipment</font> page.`},
			{ text: `Bug Fix - Fixed issue of unable to add/edit Equipment with Equipment Type - Elevators in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Equipment</font> page.`},
			{ text: `Bug Fix - Fixed issue of Site not being displayed when entering the partial Site Number from the <font color = "#007979">Change Site</font> option.`},
			{ text: `Bug Fix - Fixed issue of Branch Office information not updating in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Contract </font> page when a new Inspection Task is created for an already existing System with completed Task having Branch Office information.`},
			{ text: `Bug Fix - Fixed issue of not populating field values added in some report fields from <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: System</font> page.`},
			{ text: `Bug Fix - Fixed issue of unable to upload multiple Templates and Config files in <font color = "#007979">Manage:Inspection Settings: Reports</font> page.`}
			
		]
	},
	{
		header: 'TAP Webv8 (8.00.05) January 06th, 2023',
		content: [
			{ text: `All report templates updated for 2023 with new footer.`},
			{ text: `Edited EP report headers to include report name.`}
			
		]
	},
	{
		header: 'TAP Webv8 (8.00.04) January 02nd, 2023',
		content: [
			{ text: `All EP reports now include VHA reference codes.`}
			
			
		]
	},
	{
		header: 'TAP Webv8 (8.00.03) December 21st, 2022',
		content: [
			{ text: `New Tabs and Charts are provided under <font color = "#007979">Dasboard</font> menu. Key Performance Indicator charts and other charts are available for Super Administrators and Support Users.`},
			{ text: `Enhanced the Performance of the Tabular Reports in the Dashboard`},
			{ text: `Display of Site Name and Site Number is now provided next to each other in <font color = "#007979">Reports: Uploaded Final Reports</font>`},
			{ text: `Replaced Building Count to System Count in <font color = "#007979">Reports: Sites</font>. System Count column shows the total number of Systems against each Site.`},
			{ text: `Bug Fix - Fixed issue of not working Inspection Activity Report after changing the date range in <font color = "#007979">Reports: Inspection Activity</font>.`},
			{ text: `Bug Fix - Fixed issue of not working Inspection Task Report after changing the date range in <font color = "#007979">Reports: Inspection Tasks</font>. `},
			{ text: `Bug Fix - Fixed issue of Refresh button is not working in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Contract</font> page. Now updates in Branch Office and Customer information will be reflected in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Contract</font> page, when the Refresh button is clicked.`},
			{ text: `Bug Fix - Fixed issue of not clearing the Date field values in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Equipment Add/Edit</font> page. Now user can clear the Date field values using the Clear option in the field.`},
			{ text: `Bug Fix - Fixed issue of not allowing Apostrophe when adding or editing equipment in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Equipment Add/Edit</font> page.`},
			{ text: `Bug Fix - Fixed issue of not allowing to delete Buildings without have any Floors.`},
			{ text: `Bug Fix - Fixed issue of not displaying <font color = "#007979">Siemens Monitoring</font> check box in <font color = "#007979">Inspection: Inspection Tasks: Inspection Details: Questions</font> page, when selecting <font color = "#007979">Monitor Info</font> under <font color = "#007979">Inspection Info</font>.`}
			
		]
	},

	{
		header: 'TAP Webv8 (8.00.02) December 14th, 2022',
		content: [
			{ text: `Implemented the feature of auto - populating Status Updated column with Current Date in <font color = "#007979">Inspection: Inspection Task</font> page when a new Inspection Task is created.`},
			{ text: `Enabled Attachments feature for Closed, Inactive and Completed tasks. Earlier it was restricted to Active Tasks.`},
			{ text: `Updated Dashboard with Active Tasks and Recently Closed tabs as in old TAP V7 Web application. Active Tasks tab lists all active Tasks, whereas Recently Closed tab lists the recently closed Inspection Tasks.`},
			{ text: `Bug Fix - Issue of showing error message in the Login page is now resolved.	`},
			{ text: `Bug Fix - Issue of displaying error message while editing a system using Inline Edit option.`},
			{ text: `Bug Fix – Issue of clearing the search result  and show all data if any column is clicked for sorting after applying advanced search filter in <font color = "#007979">Inspection: Inspection Task: Inspection Details</font> page is solved. Now the search result is retained if any column is clicked for sorting. `}
		]
	},

	{
		header: 'TAP Webv8 (8.00.01) December 02nd, 2022',
		content: [
			{ text: `Bug Fix - Multiple reports were generated if there exists multiple Tasks in the selected System.`},
			{ text: `Bug Fix - Dev. Reading field was not shown in Multiple Edit page for Equipment Type - Devices.`},
			{ text: `Bug Fix - Issue with <i>Show All</i> check box in Rows per Page for Inspection Task in <font color = "#007979">Personal Settings</font>. Rows per Page for Inspection Task fields reset to 0 on selecting the Show All checkbox resulting in the empty grid in Inspection Tasks page.`},
			{ text: `Bug Fix - New Task creation to Canada Customers was not working.`},
			{ text: `Bug Fix - No Contracts were listing When searching with Contract Number and Contract Name.`},
			{ text: `Bug Fix - Issue of not retaining the scroll on pressing Backspace / DEL keys in <font color = "#007979">Inspection Details: Questions</font> page.`},
			{ text: `Bug Fix - Issue of appending underscore to beginning and end of File Name on downloading the Draft and Final Reports.`},
			{ text: `Bug Fix - Issue of System selection being empty in the <font color = "#007979">Generate Report</font> page. It is resolved by showing the first System in the list or the previously selected System as selected by default.`}
			
		]
	},
		
	{
		header: 'TAP Webv8 (8.00.00) November 20th, 2022',
		content: [
			{ text: `Implemented enhanced UI Design for Tech Advance <sup>+</sup> application.`},
			{ text: `Provided Performance Improvement.`},
			{ text: `Implemented new Dashboard with charts such as the summary of Zone wise Inspections conducted daily, summary of Zone wise Reports generated daily.`},
			{ text: `Renamed <font color = "#007979">Fire and Safety</font> module to <font color = "#007979">Inspection</font> module.`},
			{ text: `Consolidated Setup options of all modules such as definition of Sales Office, Branch Office, NFPA and Health Classifications, Attributes, Inspection Fields, Report Configs and Templates etc. are provided under <font color = "#007979">Manage</font> section.`},
			{ text: `Consolidated Reports of all modules including Inspection Reports and Administrative Reports are provided under <font color = "#007979"> Reports</font> section.`},
			{ text: `New UI for Inspection Tasks page, where the User can easily switch to another Site, Map new / existing Customers or Contracts to the Site.`},
			{ text: `Filter Customer or Contract option is provided in the Inspection Tasks page, which helps user to easily find the Inspection Tasks of the required Customer / Contract.`},
			{ text: `Provided Inline Edit feature in all grid pages, which helps user to edit a row by clicking the <font color = "#007979">Edit</font> icon against each row.`}
		]
	},

	{
		header: 'TAP App (7.01.08) January 20th, 2023',  
		content: [
			{ text: `Bug Fix -Fixed data loss issue where not all the Inspection Questions server data is being sent to the TAP App when joining a Task.` },
		]
	},

	{
		header: 'TAP App (7.01.07) December 12th, 2022', 	 
		content: [
			{ text: `Bug Fix -Fixed parsing error in devices with android versions 11 and 12 while updating app from older versions.` },
			{ text: `Bug Fix -Fixed Tap Audio Silence buttons not working in settings.` },
			{ text: `Bug Fix -Fixed issue where Tap Web is displaying TAP App updated fields multiple times.` },
		]
	},

	{
		header: 'TAP App (7.01.05) October 17th, 2022',    	 
		content: [
			{ text: `Bug Fix -Fixed an issue where TAP App would crash during Sprinkler Inspections.` },
			{ text: `Bug Fix -Fixed TAP App crashing on MNS Test click.` },
			{ text: `Bug Fix -Fixed TAP App crashing on Inspection System Edit click.` },
			{ text: `Bug Fix -Fixed TAP App crashing on Panel Edit click.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.12) September 21st, 2022', 
		content: [
			{ text: `When assigning EP and/or DNV reports to an Inspection Task, a matching NFPA report will also need to be selected.` },
			{ text: `Advanced Search in Inspection Details page will now save previous searches.` },
			{ text: `Changing tabs in Report Mapping page will no longer clear the filter.` },
			{ text: `Proper warning message shown when validating fields during excel import.` },
			{ text: `Allows dash ('-') character during excel import.` },
			{ text: `Changed default 'Equipment Type' drop-down selection in Inspection Details page to 'All'.` },
			{ text: `Changed the wording of messages in error page.<br> - Changed 'Something went wrong. Please try again later.' message to 'Server is not responding. Please re-login to continue.'.<br> - Changed 'Tech Advance+ encountered a problem while executing your command.' message to 'An error occured while processing your request.'.<br/> - Changed 'A potentially dangerous Request.Path was detected from the client.' message to 'An error occured while processing your request.'.` },
			{ text: `Ignores case of mail domain while checking its validity when adding or editing users.` }
		]
	},

	{
		header: 'TAP App (7.01.04) August 23rd, 2022',          
		content: [
			{ text: `Bug Fix -Fixed untested filter not working.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.11) August 16th, 2022',        
		content: [
			{ text: `Password Policy feature updated.` },
			{ text: `Special character checking while saving Panel Name.` },	
			{ text: `Added Drawing logs, so that we can identify which drawing is opened by a user.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.10) August 08th, 2022',         
		content: [
			{ text: `Bug Fix -Fixed an issue where empty test results would be imported when using 'Import Previous Task Results' feature.` },
		]
	},

	{
		header: 'TAP Webv7 (7.01.09) August 01st, 2022',        
		content: [
			{ text: `TAP Yammer page link embedded in the header page.` },
			{ text: `Updated TAP System so it can accept apostrophes from SAP.` },
			{ text: `Updated TAP System so that timeout only occurs after two hours of inactivity.`},
			{ text: `TAP will now show a popup message if excel contains any non-typed keyboard characters while importing equipment.`},
			{ text: `TAP will not accept any attribute values not specified by the system while importing equipment. These values will be imported as null instead.` },
			{ text: `TAP will now show a general warning on clicking 'Import Previous Task Results'.`},
			{ text: `Bug Fix -Fixed an issue where a user could be added with leading and/or trailing spaces in the login name.`},
		]
	},

	{
		header: 'TAP Bridge (6.05.02) July 10th, 2022', 
		content: [
			{ text: `Correct Chronic issue with TAP Bridge of resending all equipment lis` },
			{ text: `UI changes for Inspection Without Internet mode<br/> - Changed the label "Without Panel connection" to "Sync with Server" for better definition of the selection box<br/>- Added tooltip "Check this box when sending test results for inspection without internet mode"<br/> - Hide the “Sync with Server” during "inspection with internet" and "troubleshooting mode" mode of inspections.` },
			{ text: `Log updates for when Sync is completed (config is downloaded from the server) during “inspection without internet” mode. The user will see a message in the Console Viewer stating it is okay to disconnect from the server.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.08) July 10th, 2022',    
		content: [
			{ text: `User edit issue fixed` },
			{ text: `Added 5 New <font color = "#007979">System </font> Types (Exit Lights, ERCES, Area of Refuge, Stairwell Pressurization, Siemens) and removed 'Others' type.` },
		]
	},

	{
		header: 'TAP App July 10th, 2022', 
		content: [
			{ text: `Fixed issue where few extra question nodes found compared to Tap Web.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.07) June 19th, 2022', 
		content: [
			{ text: `Logbook is now implemented.` },
			{ text: `Corrected the spelling of 'SharePoint' in Help` },
			{ text: `Customers with '-Demo-' will now exclueded from <font color = "#007979">Inspection </font>Activity Reports.` },
			{ text: `Removed iShare from the email templates.` },
			{ text: `Bug Fix -Fix for 'Lead Technician' column not appearing in <font color = "#007979">'Inspection Tasks' </font> page for 44OP projects.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.06) May 29th, 2022', 
		content: [
			{ text: `<font color = "#007979">Inspection Tasks</font> will not be able to be deleted by anyone except Super Admin and Support users, if there are Final reports uploaded.` },
			{ text: `New Equipment Type 'ERCES' added.` },
			{ text: `Bug Fix -Fixed issue of TAP Web crashing when searching for User with apostrophe (') in the name.` }
		]
	},

	{
		header: 'TAP App (7.01.03) May 29th, 2022', 
		content: [
			{ text: `New Equipment Type 'ERCES' added.` },
		]
	},

	{
		header: 'TAP Webv7 (7.01.05) May 15th, 2022', 
		content: [
			{ text: `When all selected reports have not been created, TAP will show a list of reports that have not been generated and show them in an error popup window, when trying to Complete the Task. The new column will be present in the following pages :` },
			{ text: `Added a new column to show days to upload after last device was tested so, we can monitor how long it takes to provide a report after the last device was tested.<br/>-In Dashboard 'Active Inspection Tasks', after the "Report(s) Uploaded" column.<br/>-In Inspection Reports screen after the "Date and Time Uploaded" Column.<br/>-In Inspection Activity report after the "Final Inspection Report(s) generated" column.` },
			{ text: `Support for latest AutoCAD version drawings. (Note : Cache must be cleared from the browser before opening any drawings, to purge the old libraries.)` },
			{ text: `Bug Fix -Fix for Pan, Zoom, and Move controls in <font color = "#007979">Fire and Safety-> Inspection Task->Drawings</font> screen.` },
			{ text: `Bug Fix -Fix for closing task in the Dashboard-> Close Inspection Task screen and in the "Task require quitting" selection that was preventing a Super user from closing a task in this list.` }
		]
	},

	{
		header: 'TAP App (7.01.02) May 15th, 2022', 
		content: [
			{ text: `Added a new functionality where if the joined inspection task is closed, the tech is free to join a new task.` },
			{ text: `Bug Fix -Fix for Samsung PASS update that was blocking users from using TAP App when they also used Samsung PASS on their mobile devices.` },
		]
	},

	{
		header: 'TAP Webv7 (7.01.04) Mar 14th, 2022', 
		content: [
			{ text: `New column "Status Updated" added in Inspection Task grid page, current date will be updated to the column on changing the Task status.` },
			{ text: `When Session out happens, application will be redirected to login page after a pop-up message.` },
			{ text: `When user tries to close an active Task, a reminder message will be displayed as "Please make sure all final reports are signed and uploaded".` },
			{ text: `User can now Quit from an active Task in the Administration -> Users page itself.` },
			{ text: `Branch Details are now mandatory when generating reports.` },
		]
	},

	{
		header: 'TAP Webv7 (7.01.03) February 21st, 2022', 
		content: [
			{ text: `Move functionality now implemented.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.02) February 11th, 2022', 
		content: [
			{ text: `New feature "Import Previous Task Details" added in Inspection Task page -> More menu. Users can now copy Equipment Test Results, System and Questions data into the selected Active Task from any Completed Task of the same System.` },
			{ text: `Implemented "All" option in Customer and Contract dropdown list in Inspection Task page when more than one customer or contract is present in the lists.` },
		]
	},

	{
		header: 'TAP Webv7 (7.01.01) January 19th, 2022', 
		content: [
			{ text: `Paging in 'Inspection Task' page will now follow the 'Rows per Page for Inspection Tasks' value in 'Personal Settings'.` }
		]
	},

	{
		header: 'TAP Webv7 (7.01.0) January 17th, 2022', 
		content: [
			{ text: `TAP has been restructured, Customer Hierarchy has been changed to Site Hierarchy.` },
			{ text: `New System Life Cycle is now implemented. Now Systems no longer need to be copied between Customers.` },
			{ text: `Inspection Tasks can now be set as 'Completed' after all its reports are generated. The lifetime of completed tasks is over and cannot be reactivated by technicians.` },
		]
	},

	{
		header: 'TAP App (7.01.0) January 17th, 2022', 
		content: [
			{ text: `TAP has been restructured, Customer Hierarchy has been changed to Site Hierarchy.` },
			{ text: `New System Life Cycle is now implemented. Now Systems no longer need to be copied between Customers.` },
			{ text: `Inspection Tasks can now be set as 'Completed' after all its reports are generated. The lifetime of completed tasks is over and cannot be reactivated by technicians.` },
			{ text: `Pen&PDF is replaced by Xodo for viewing reports.` }
		]
	},

	{
		header: 'TAP Webv6 (6.05.10) August 30th, 2021',                
		content: [
			{ text: `Bug Fix - The issue where '12:00 AM' appeared erroneously on reports has been fixed.` }
		]
	},
	{
		header: 'TAP App (6.03.33) August 11th, 2021', 
		content: [
			{ text: `Password Masking feature is now available` },
			{ text: `TAP App can now only be updated via Google Play Store.` },
			{ text: `Bug Fix -Filter issue in Equipment List is now fixed.` },
		]
	},

	{
		header: 'TAP Webv6 (6.05.09) August 2nd, 2021', 
		content: [
			{ text: `Removed TAP App Download area from plugins, now user should update or install TAP App from Google Play Store.` },
		]
	},

	{
		header: 'TAP App (6.03.31) July 28th, 2021', 
		content: [
			{ text: `Tap App will no longer allow login from any version below 6.03b31.` },
		]
	},

	{
		header: 'TAP App (6.03.31) June 20th, 2021', 
		content: [
			{ text: `The new apk will fix the update issue that is present in newer android versions.` },
		]
	},

	{
		header: 'TAP Webv6 (6.05.08) June 11th, 2021', 
		content: [
			{ text: `Following System types added:<br/> -CP-2ER<br/> -Pyrotronics<br/> -PXL<br/> -FCI` },
			{ text: `Bug Fix -Fixed issue where Barcode data is not moving with systems when Move/Copy feature is used in TAP Web.` }
		]
	},

	{
		header: 'TAP  Reporting - May 16th, 2021', 
		content: [
			{ text: `Added footnotes for FirePump graphs` },
			{ text: `Changed name “Performance Corrected Net PSI” to “Net Speed Adjusted PSI” for the FirePump graphs.` }
		]
	},

	{
		header: 'TAP Webv6 (6.05.07) April 25th, 2021', 
		content: [
			{ text: `In Branch Office add/edit page, excluded 'NA' from format validation for Phone, Fax and Email fields.User can enter NA in fields if there is no data available.` },
			{ text: `Added more detailed logs to catch exceptions from Inspection Task,Inspection Details,Systems and Reports pages.` }
		]
	},

	{
		header: 'TAP  App (6.03.29) April 25th, 2021', 
		content: [
			{ text: `Bug Fix -Fixed the issue while filtering the equipment list by Test Result.` },
		]
	},

	{
		header: 'TAP Webv6 (6.05.06) March 29th, 2021', 
		content: [
			{ text: `Added new status column 'Sent to Service Portal' in report listing page to find whether or not the file transferred to Portal.` }
		]
	},

	{
		header: 'TAP Webv6 (6.05.05) March 20th, 2021', 
		content: [
			{ text: `Now user can also upload 7zip files to the Config File of a System.` },
			{ text: `TAP web will show a Disconnection Notification message when a user session time out happens from TAP Web in Inspection Task, Inspection Task Details & System pages.` },
			{ text: `TAP Integration of Canada Contracts` },
			{ text: `Reporting changes to support the newly designed NFPA reports.` },
			{ text: `Bug Fix -Fix for FS20 Panel name to show up correctly on TAP Web Equipment Group.` }
		]
	},

	{
		header: 'TAP App (6.03.29) February 21st, 2021', 
		content: [
			{ text: `Device Reading field is now also listed in Device Add page, earlier the field was displayed only in Device Edit page.` },
		]
	},

	{
		header: 'TAP Webv6 (6.05.04) February 21st, 2021', 
		content: [
			{ text: `Added new System Type 'Mircom' per Canada's requested.` },
			{ text: `Device Reading field is now listed in Device Add page. Previously, the field was displayed only in Device Edit page.` },
			{ text: `For our TAP Web developers, data entry settings for Equipment type and NFPA classification will be mapped uniquely.` },
			{ text: `Table headers with 'equipment type' now repeat when crossing pages on reports.` },
			{ text: `Added many new classifications to Water Based Equipment, and enhanced NFPA 25 and EP reports to utilize these new classifications.` },
			{ text: `Bug Fix -Fixed issue of "Memory out of exception" in Report generation. This fixes the issue we had when running large reports.` },
		]
	},

	{
		header: 'TAP App (6.03.27) January 31st, 2021', 
		content: [
			{ text: `Equipment Add/Edit screen now same as that in Tap Web. Devices have Module as a selectable item, Water Based Equipment have Sprinkler as a selectable item, All Other types have neither.` },
			{ text: `New feature 'Upload Signed Final Report' added in Final Report list Page. Now user can upload signed final report from TAP App.` },
			{ text: `Label name 'Panel' is changed to 'Equipment Group’.` }
		]
	},

	{
        header: 'TAP Webv6 (6.05.03) January 31st, 2021',
        content: [
            { text: `Search functionality provided in Admin Module.` },
            { text: `Removed Customer's 'Description of Property' from Inspection Details -> Contract Tab -> Customer Details.` },
            { text: `Label name 'Panel' is changed to 'Equipment Group'.` },
            { text: `'REC' column in Inspection Details page is now getting copied when copying System.` },
            { text: `Added new Site Number Pattern for Canada.` },
            { text: `Added City of Vancouver Certification report.` },
			{ text: `Updated NFPA72 2010 report so that all fields are collected in TAP and auto populated in the report.` },
			{ text: `Updated NFPA25 Monthly Sprinkler Inspection Report.` },
			{ text: `Added NFPA25 3 Year Inspection Report.` },
            { text: `Bug Fix -Fixed issue while adding 'Owner's name' without selecting 'Location Address of System' in Inspection Details -> System Tab.` },
			{ text: `Bug Fix -Fixed sorting issue of Equipment Group in Report Generation.` }
        ]
    },

	{
        header: 'TAP Bridge (6.03.04) January 9th, 2021',
        content: [
            { text: `Bug Fix -Resolved bug with Supervisory Sound Playback.` },
			{ text: `Bug Fix -Resolved bug with wrong notification of version update.` }
        ]
    },

	{
        header: 'TAP App (6.03.25) December 20th, 2020',
        content: [
            { text: `Equipment Types - 'Fire Department Connections', 'Fire Hose and Hose Valves', 'Private Fire Service Mains' and 'PRV' consolidated to new Equipment Type 'Water Based Equipment'.` },
            { text: `All the existing attributes and data of 'Fire Department Connections', 'Fire Hose and Hose Valves', 'Private Fire Service Mains' and 'PRV' are moved to 'Water Based Equipment'.` },
            { text: `New menu option - 'Generate Reports' added in Equipment List Page. Users can generate the Final Reports.` },
            { text: `New menu option - 'Final Reports' added in Equipment List Page.Users can view the Final Reports by selecting this option.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.05.02) December 20th, 2020',
        content: [
            { text: `Equipment Types - 'Fire Department Connections', 'Fire Hose and Hose Valves', 'Private Fire Service Mains' and 'PRV' consolidated to new Equipment Type 'Water Based Equipment'.` },
            { text: `All the existing attributes and data of 'Fire Department Connections', 'Fire Hose and Hose Valves', 'Private Fire Service Mains' and 'PRV' are moved to 'Water Based Equipment'.` },
            { text: `Changed Excel export format from xls to xlsx for all reports in TAP Web.` }
        ]
    },

	{
        header: 'TAP  Bridge (6.03.03) December 14th, 2020',
        content: [
            { text: `Automatic cleanup of log data.` },
            { text: `Displaying sensitivity information in printer log .` }
        ]
    },

	{
        header: 'TAP App (6.03.20) November 15th, 2020',
        content: [
            { text: `Provided a new option to enter Barcode data manually from TAP App for situations when the Barcode is challenging to scan with you phone's camera.` },
            { text: `Bug Fix -Fixed issue of not showing value in 'Health Classification' in the Edit equipment view if the 'Health classification' is added without selecting 'NFPA classification'.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.05.01) November 15th, 2020',
        content: [
            { text: `Removed Adobe Flash dependency in all webpage reports. This eliminates the Android Flash error messages. This feature is not supported in Internet Explorer.(Preferred Browsers : Microsoft Edge, Google Chrome, Mozilla Firefox)` },
            { text: `System now validates the XML file types while uploading. Detects XML File System Type to prevent uploading of the wrong file type to a System.` },
            { text: `The Phone, Fax, Email and Contact fields are now made mandatory with default data 'None' while creating a new Customer.` },
            { text: `All the existing Customer's Phone, Fax, Email and Contact data has been changed to "None" if the data fields were empty.` },
            { text: `In the Proven Outcomes' excel battery sheet, each of the Fire Alarm Panel's 'Battery Manufacturing Date' and 'Location' is added on the top of the list. This only affects the CSMs and has no affect to inspections or inspection reporting.` },
            { text: `Changed the position of 'Battery Manufactured Date' in Secondary power info below to the 'CU Calculated Alarm Minutes' to improved the flow of related questions filed in by the techs.` },
			{ text: `Removed Training Materials section from Dashboard because this information is now located on the TechAdvance+ Yammer and Share Point sites.` },
			{ text: `Changed the label 'Drafted Reports' to 'Reports' in report generation and report listing page because we previously combined 'Drafted Reports' and 'Final Reports' on one page and the name needed updating.` },
			{ text: `In SAP to Tap Integration which auto imports new contracts from SAP to TAP, special characters have been blocked and replaced with acceptable characters while importing. This has no affect to the techs inspection activity.` },
			{ text: `Enhanced Private Service Main's NFPA report to include graphing of the flow results.` },
            { text: `Bug Fix -Fixed issue of not showing value in 'Health Classification' in the Edit equipment view, if the 'Health classification' is added without selecting 'NFPA classification'.` },
			{ text: `Bug Fix -Fixed issue of not downloading the PDF with the proper file name when downloading the PDF from the Drafted Report viewer.` },
			{ text: `Bug Fix -Fixed issue of not sorting the Drafted reports default display by 'Date & Time Created'.` },
        ]
    },

	{
        header: 'TAP App (6.03.19) October 22nd, 2020',
        content: [
            { text: `Changed Equipment Type name “Kitchen Extinguishers” to “Kitchen and Dry Chemical Ext Systems”.` },
            { text: `Panel Id is now being sent to Tap Bridge on requesting sensitivity when All Groups selected.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.17) October 22nd, 2020',
        content: [
            { text: `Changed Equipment Type name “Kitchen Extinguishers” to “Kitchen and Dry Chemical Ext Systems”.` },
            { text: `Bug Fix -Fixed Document Access issue for Tech users in Document Module.` }
        ]
    },

	{
        header: 'TAP Bridge (6.03.02) October 12th, 2020',
        content: [
            { text: `Repaired crash issue caused when using the 'Without Panel Connection' feature.` }
        ]
    },

	{
        header: 'TAP Bridge (6.03.01) October 11th, 2020',
        content: [
            { text: `TAP Bridge updated again due to a required security update.Installing the new version is required to continue using TAP Bridge. The new version is 6.03.01.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.16) October 11th, 2020',
        content: [
            { text: `TAP Web will be updated to parse the 14.2 and newer Zeus XML files.` },
            { text: `TAP Web will be able to parse Zeus files with PAD5 equipment and automatically place the PAD5 panels in the NAC Panels list. The PAD5 circuits will be placed in the Modules list to allow users to link notification devices to the circuits.` }
        ]
    },

	{
        header: 'TAP App (6.03.17) September 27th, 2020',
        content: [
            { text: `The 'Tested Date' field is no longer hidden on the Equipment Edit page, even if there is no date. As a reminder, if equipment has not been tested, 'Tested Date' field will be updated when changing either 'Test Method' or 'Test Result' fields. If equipment has been tested, 'Tested Date' field will be updated only when changing 'Test Result' field.` },
            { text: `Bug Fix -Fixed an issue with data syncing while adding multiple equipment via TAP App.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.15) September 27th, 2020',
        content: [
            { text: `The 'Tested Date' field is no longer hidden on the Equipment Edit page, even if there is no date. As a reminder, if equipment has not been tested, 'Tested Date' field will be updated when changing either 'Test Method' or 'Test Result' fields. If equipment has been tested, 'Tested Date' field will be updated only when changing 'Test Result' field.` },
            { text: `Added a new feature which allows a user to view Drafted Reports without downloading them. The draft Word version is converted to PDF and then displayed on TAP Web when using this feature. The PDF can then be downloaded for capturing a signature or using as a final version.` },
            { text: `PDF files uploaded as a Final Report will now have the file name changed to match that of the corresponding Drafted Report.` },
            { text: `For the new Proven Outcomes feature, in<font color = "#007979"> Fire and Safety: Systems:</font> Proven Outcomes page, the Task list now sorted with newest on the top and Inspection Task name will be displayed with Task End Date then followed by Task name.` },
            { text: `While adding a new Inspection task, limited Inspection Task Planned End Date to 60 days from Current Date.` },
            { text: `System Type name 'Desigo FC20' is now properly named 'Desigo 50'.` },
			{ text: `Removed the 'Description of Property' field from the Customer Add, Edit and List page because it is an unused field and not needed as a Customer data field.` },
            { text: `Bug Fix -Fixed an issue when French text characters are in the Location field while updating NFPA Classification.` },
			{ text: `Bug Fix -Fixed an issue when searching Inspection task by name. The list was filtering to the 'Last 3 months' when it should be filtering to the 'Last 18 months' after a search.` },
        ]
    },

	{
        header: 'TAP App (6.03.16) August 15th, 2020',
        content: [
            { text: `Fields in Update Status page are integrated to Edit Equipment page.` },
            { text: `Removed 'Update Status' option from Equipment List.` },
            { text: `Removed 'Reset' option.` },
            { text: `In Equipment Edit, provided 'Close' button to the right side of 'Delete'. The Close button will remind user to save their edits if unsaved edits are detected.` },
            { text: `In Equipment Edit, when user selects 'Save' option, the Edit window will not close.` },
            { text: `Changed Equipment Type name “Private Fire Service Main” to “Private Fire Service Mains”.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.14) August 15th, 2020',
        content: [
            { text: `Fields in Update Status page are integrated to Edit Equipment page [Note: 'Test Date' will be updated only if there is a change in 'Test Result' while updating the Equipment].` },
            { text: `Removed 'Update Status' option from Inspection Details: Equipment page.` },
            { text: `Enabled the accessibility of 'Copy and Move' feature for 'Support user' roles.` },
            { text: `Implemented Logs for Copy and Move feature and the option will be available under <font color = "#007979">Fire and Safety: Tools : Copy / Move Logs</font> . This will provide the detailed list of actions performed for Copy and Move feature. Export to Excel option is also provided with this option. This Log will be available only for Super Administrators.` },
            { text: `Updated the Inspection Activity Report in <font color = "#007979">Fire and Safety: Administrative Reports</font> with 'Sales Office Codes' next to the 'Sales Office Name'` },
            { text: `Provided 'Last 18 Months' filter in Inspection Tasks List and that will be the default filter in the Inspection Tasks page.` },
			{ text: `Changed Equipment Type name “Private Fire Service Main” to “Private Fire Service Mains”.` },
			{ text: `Bug Fix -Fixed issue in auto import of data with '-' character. This will be replaced with a hyphen(-).` },
            { text: `Bug Fix -Fixed adding Date fields(Equipment Type Attributes) in wrong format. User will not be allowed to enter wrong date format.` },
        ]
    },

	{
        header: 'TAP App (6.03.13) July 18th, 2020',
        content: [
            { text: `Data Entry Settings based on Classification.<br/>
			- This filter will allow the user to control the data fields of a certain equipment type based on its NFPA classification. This will not be a mandatory field.` },
            { text: `Removed showing the ring tone name of 'TAP Notifications' from 'Settings'.` },
            { text: `New SSL Certificate pinned with apk. Please update this apk before 29th July 2020.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.12) July 18th, 2020',
        content: [
            { text: `Data Entry Settings based on Classification.<br/>
			- New filter, 'NFPA Classification' added under <font color = "#007979">Fire and Safety: Setup: Data Entry Settings: Equipment Type Attributes</font>.<br/>
			- This filter will allow the user to control the data fields of a certain equipment type based on its NFPA classification. This will not be a mandatory field.` },
            { text: `In <font color = "#007979">'Fire and Safety: Inspection Reports: Drafted Reports</font>', Drafted report list is now sortable by Date and Time.` },
            { text: `In Proven Outcomes, option provided to generate excel for any task under the System. User should select a task and click on 'Download' button for downloading excel instead of mail.` },
            { text: `Site Description field made mandatory while adding or editing a Site.` },
            { text: `In As Builts module, Site Name will be displayed along with the Site Number.` },
            { text: `Changed report menu 'Inspection Reports: Drafted Reports' to 'Inspection Reports: Reports'.` },
            { text: `Bug Fix -Fixed sorting and paging issues in FTP Import Status Page.` },
			{ text: `Bug Fix -Debugger removed from inspection Task(List, Add, Edit) page and Dashboard page.` },
			{ text: `Bug Fix -Fixed web page exception in the dashboard page.` }
        ]
    },

	{
        header: 'TAP Reporting (6.01.17) July 18th, 2020',
        content: [
            { text: `Texas form report changes.` },
            { text: `Bug Fix -Fix for showing Duplicate equipment's in the report.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.04.11) June 14th, 2020',
        content: [
            { text: `New field "Owner or Owner's Representative Name" is now populating reports for showing the name of the person signing the inspection report.` },
            { text: `The 'Description' field in the Site data is now populating reports with a Site Description field.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.10) June 06th, 2020',
        content: [
            { text: `Added the ability to have default data in inspection data fields to reduce the amount of edits needed for common answers and locations where NA could already by entered.` },
            { text: `Auto importing capability. A new Scheduler application will run every day and import Customers, Sites, and Contract provided by SAP when a new or renewal booking occurs.` },
            { text: `New feature of 'Advanced Search' is added under Fire and Safety: Inspection Details page. This option allows users to search for Equipment details by building a query for the selection criteria. The query can be built, specifying conditions one by one, and then grouping them as required. Multiple conditions can be added using 'AND' or 'OR' operators. The filter configuration is saved for reuse and editable.` },
            { text: `For all user levels, Customer and Branch information in the Contract tab is limited to view only. This information can only be edited in the Add/Edit screen under the Administration: Setup: Sold to Customers and Administration: Branch Office menu. A new refresh option is provided for existing task to update Customer and Branch data from the current master list for each.` },
            { text: `Added a new field 'Battery Manufactured Date' in Signal Extenders equipment for proper battery replacement planning. This will also feed into a new program called Proven Outcomes for CSMs and CSAs service account reps.` },
            { text: `While adding an Inspection task, fixed the issue of not validating the System selection if no task exists.` },
			{ text: `Added new field "Owner or Owner's Representative Name” in Inspection Task Details System tab to allow technicians to enter the specific name of the person signing the inspection reports.` },
			{ text: `The NFPA72-G report now includes the failed Devices of Remote Relays, Relays, Call Boxes, Fireman's Phones, Phone, Jacks, and all equipment in the Modules list.` },
			{ text: `Bug Fix -Fixed issue of not showing Final Report in TAP Web, if the file name contains '&' character.` },
            { text: `Bug Fix -Fixed issue of not generating Drafted Report in TAP Web, if the file name is lengthy. File name length limited to 150 characters but still retains a date stamp at the end of the file name.` },
        ]
    },

	{
        header: 'TAP App (6.03.09) May 02nd, 2020',
        content: [
            { text: `Added sound for 'ALARM ACK', 'SUPERVISORY ACK' and 'TROUBLE ACK'.` },
            { text: `New Equipment Type - 'Private Fire Service Main' added.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.09) May 02nd, 2020',
        content: [
            { text: `'Set as System Default' button is provided in the 'Customize' option of all Administrative reports. It allows the Super Admins to save the Settings made in the Reports as the default System Settings.` },
            { text: `A Logged Method Indicator field 'Rec' is provided in the Fire and Safety: Inspection Tasks: Inspection Details: Equipment list next to 'Test Result' field to indicate whether the Equipment is inspected from TAP Web, TAP App, or TAP Bridge. 'Rec' field will be auto-populated with value 'TW' if the Status - Passed/ Failed value is updated from TAP Web, 'TB' for Tap Bridge and 'TA' for TAP APP.` },
            { text: `Provided option to modify the Contract End Dates to past Dates through Fire and Safety: Contracts for all User Roles other than Techs and Branch Users.` },
            { text: `Provided option to change the System Type of existing 'XLS' Systems to 'Desigo Modular' through <font color = "#007979">Fire and Safety</font>: Systems: Edit option.` },
            { text: `New Report 'Expired Contracts' is added under Fire and Safety: Administrative Reports to view the list of the expired Contracts with Active or Inactive Tasks. This report is available only for Super Admins.` },
            { text: `A new Scheduler application will run in an interval of 24 hours, which will close the expired contracts automatically, if the Contract does not have Active or Inactive Tasks.` },
			{ text: `New Equipment Type - 'Private Fire Service Main' added.` },
            { text: `Bug Fix -Fixed server down issue while copying big systems.` },
        ]
    },

	{
        header: 'TAP App (6.03.07) Apr 04th, 2020',
        content: [
            { text: `Moved Device Reading from 'Update Status' to the 'Edit' view of equipment. This change will prevent the unnecessarily display of Device Reading on equipment not needing to have this field in the testing questions.` }
        ]
    },

	{
        header: 'TAP Webv6  (6.04.07) April 04th, 2020',
        content: [
            { text: `Fire Proven Outcomes export option added in Systems Page. This is to support the development and testing of a new Proven Outcomes document creation.` },
            { text: `System Address made mandatory in System Tab of Inspection Tasks Details Page and the label will be changed to "Location Address of System". This is to be more clear that the Street, City, State and Zip are to be entered in the field. Many users had misunderstood the field and entered a Node address. This should clear the confusion and force the field's population because it is populated on many report templates.` },
            { text: `Added a user lookup feature under Administration: Users: Search User. This helps find a user in the system by search across all user levels.` },
            { text: `Moved 'Dev Reading' field edit from 'Update Status' to the 'Edit' view of the equipment. 'Dev Reading' placed below 'Make /Model' in the Edit view. This change will prevent the unnecessarily display of Device Reading on equipment not needing to have this field in the testing questions.` },
            { text: `Provided final reports view on web page without downloading them. This will allow the viewing of Final PDF reports to be viewed within the browser window and remove the need to download the PDF file in order to view it.` },
            { text: `Provided option to download multiple final reports. This allows a user select and download many Final PDF reports instead of downloading only one at a time.` },
			{ text: `Provided 'Status' as default column in Fire and Safety: Administrative Reports: Inspection Tasks report. Now the report can be ran and exported to show Status of the Inspection Task.` },
            { text: `Date range selection limited to 3 months in Logbook view and 1 year for 'Inspection Tasks', 'Inspection Activity' and 'Uploaded Final Reports' under Fire and Safety: Administrative Reports.` },
        ]
    },

	{
        header: 'TAP Bridge (6.01.16) February 29th, 2020',
        content: [
            { text: `Desigo Compact: Better Desigo CC and Desigo FV20 compatibility, better connectivity for multi node and ethernet systems.` },
            { text: `Bridge: Better handling of network and TAPweb connection timeouts and restoration, without hanging up Bridge.` },
            { text: `Bridge: Better detection of XND device installations on Windows 10 and update to driver.` }
        ]
    },

	{
        header: 'TAP  App (6.03.04) February 29th, 2020',
        content: [
            { text: `Changed Equipment Type name from "Fire Hoses" to "Fire Hoses and Hose Valves".` },
            { text: `Following Equipment List UI Changes done.<br/>
			- Disabled the pop up tool bar while selecting an Equipment.<br/>
			- Provided a separate icon to show equipment details.<br/>
			- Removed 'Equipment Reset' option from the equipment details view and added to 'Update Status' View.<br/>
			- Removed Delete option from 'long press' event and added this option in 'Equipment Edit' View.` },
            { text: `Bug Fix -Fixed issue while scanning a Bar code. (If the bar code and Equipment Address are Similar).` },
        ]
    },

	{
        header: 'TAP Webv6  (6.04.05) February 29th, 2020',
        content: [
            { text: `Changed Equipment Type name from "Fire Hoses" to "Fire Hoses and Hose Valves".` },
            { text: `Following System types added.<br/>
			- Extinguishers<br/>
			- FS-250<br/>
			- Mass Notification<br/>
			- Sphinx<br/>
			- Suppression<br/>
			- Swiss<br/>
			- SXL<br/>
			- System 3<br/>
			- TRX<br/>
			- TXL<br/>
			- XL3<br/>
			- Desigo FC20` },
            { text: `Added following TAP Web links to Temporary Password emails.<br/>
			- TAP Web<br/>
			- TAP iShare<br/>
			- TAP Yammer` },
        ]
    },

	{
        header: 'TAP Proxy February 10th, 2020',
        content: [
            { text: `Bug Fix -Fixed issue while parsing XML file from TAP Web for 'Admin' User Roles.` },
        ]
    },

	{
        header: 'TAP Reporting (6.01.16) February 08th, 2020',
        content: [
            { text: `Support NFPA Reports with EP-style tables.` },
            { text: `Bug Fix -Fix TOC and spacing on NFPA reports.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.04.04) February 08th, 2020',
        content: [
            { text: `Admin Details report added under Administrative Reports.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.04.03) January 27th, 2020',
        content: [
            { text: `Provided an option to copy Siemens Monitoring Information in Inspection Task Details Questions page.` },
            { text: `Enabled Status and Branch filter to Contracts report for all users.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.04.02) January 20th, 2020',
        content: [
            { text: `Enabled all privileges for Admin User Role in 'Systems' & 'Inspection Task' pages.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.04.01) January 07th, 2020',
        content: [
            { text: `Added new Pattern - 1letter-SI-5digits for Canada in Contracts page.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.04) January 05th, 2020',
        content: [
            { text: `New User Roles added.<br/>
			- Support.<br/>
			- Admin-Tech.<br/>
			- Branch.` },
            { text: `Existing User Roles Changed.<br/>
			- Branch Administrators -> Admin.<br/>
			- Technicians -> Tech.` },
            { text: `Blocked Techs from editing the Contract Tab of Inspection Task Details Page.` },
            { text: `Enabled editing of non-Siemens systems types in Edit Systems screen.` },
            { text: `Provided Max Number of Characters edit field for multi-line text area edit screen in Inspection Field Questions Tab.` },
            { text: `Edit and update options enabled for All Equipment Groups display in Inspection Task Details Page.` },
            { text: `Bug Fix -Fixed issue with getting updated Branch Information for a newly created Task.` },
        ]
    },

	{
        header: 'TAP Reporting (6.01.15) November 18th, 2019',
        content: [
            { text: `Support “RowFilter” in TableInstance.` },
            { text: `Support “Formula” in TableDefinition.` },
            { text: `Support Visually Tested data fields in EP Summary.` },
            { text: `Support Additional Fire Pump Chart data series, and new larger layout.<br/>
			- 5% degraded.<br/>
			- Net Corrected.` },
        ]
    },

	{
        header: 'TAP App (6.03.01) October 28th, 2019',
        content: [
            { text: `New Equipment Type - 'PRV' added.` },
            { text: `Provided 'All' view for Equipment Group in Equipment List Page.` },
            { text: `Style changed for Equipment Group and Equipment Type Drop down in Equipment List Page.` },
            { text: `TAP icon changed.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.03.02) October 28th, 2019',
        content: [
            { text: `New Equipment Type - 'PRV' added.` },
            { text: `Provided 'All' view for Equipment Group in Inspection Task Details Page.` },
            { text: `Removed the Edit feature of Sites and Contracts for Technicians.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.03.01) September 30th, 2019',
        content: [
            { text: `Framework 4.6.1 update done.` },
            { text: `Increased the size of Inspection Field Question text from 150 to 200.` },
            { text: `New State removed for Inspection Task. While adding a new Inspection Task, it will move to Active State.` },
            { text: `Bug Fix -Fixed issue with deleting a question from Inspection Fields if there is an apostrophe in the text.` }
        ]
    },

	{
        header: 'TAP Bridge (6.01.15) September 16th, 2019',
        content: [
            { text: `Better BACnet compatibility (BDT support (for GMS systems), better Troubleshooting mode).`},
            { text: `Safe XND exit workflow to ensure XND panels (XLS/MXL) are left in a working mode.` },
            { text: `XLS PAD5 support, Canadian mode enhancements (multi switch manual pulls etc.).` },
            { text: `HNET performance improvements.` },
            { text: `Prevent loading an inspection while TAPweb is parsing the system configuration.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.18 to 19) September 09th, 2019',
        content: [
            { text: `Equipment Address made unique to an Inspection Task instead of a System.` },
            { text: `Enabled sorting in Dashboard - Active Task List.` },
            { text: `Report Template/Config file upload validation message changed to popup message.` },
            { text: `TAP icon changed.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.17) August 26th, 2019',
        content: [
            { text: `Allowed Branch Admins to close a Contract.` },
            { text: `Blocked Closing of System while closing Contract.` },
            { text: `Restricted the creation of new Inspection Task under a Closed Contract.` },
            { text: `Added Status and Branch filter for Contract Reports - 'Fire and Safety: Administrative Reports: Contracts'.` },
            { text: `Modified Equipment Summary List Report to include Visually Tested devices.` },
            { text: `Limited 'Mail Domains' Menu access to super admin only.` },
			{ text: `New Equipment Type - 'Air Sampling Systems' added.` },
			{ text: `Applied timeout fix to avoid getting blank columns in Inspection Task Details` }
        ]
    },

	{
        header: 'TAP App (6.02.13 to 14) August 25th, 2019',
        content: [
            { text: `New Equipment Type - 'Air Sampling Systems' added.` },
            { text: `Bug Fix -Fixed issue of not showing location text while adding a new device.` },
        ]
    },

	{
        header: 'TAP Proxy August 26th, 2019',
        content: [
            { text: `New API IsTapWebParsingInProgress added in Proxy - to check whether parsing from Tap Web is in progress.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.15 to 16) July 29th, 2019',
        content: [
            { text: `Added new Pattern - 8-digits starting "1" for the USA in Sold to Customer page.` },
            { text: `Provided Test Date as mandatory (selected and disabled) while updating the Equipment Test result to Passed or Failed through Update Status.`},
            { text: `Provided option to hide the selected layer in the Drawing under Inspection Tasks List.` },
            { text: `As Builts: Drawings: Markup - Provided option to move and delete the shapes after saving.` },
            { text: `As Builts: Drawings: Markup - Provided option to edit the text after saving.` },
            { text: `As Builts: Drawings: Markup - Callout feature added in Markup.` },
			{ text: `As Builts: Drawings: Markup - Provided option to highlight the Text in Add /Edit Text Markups.` },
            { text: `Update Status - Removed 'Update Test Date' check box from Update Status Window.` },
            { text: `Update Status - If Test Method 'None' is selected in the Update Status window, the option 'Not Tested' only will be displayed as selected in the Test Result. If any Test Date exists for the selected Equipment, then it will be cleared.` },
            { text: `Update Status - If Test Method other than 'None' is selected in the Update Status window, the options 'Passed' and 'Failed' will be displayed in the Test Result and 'Passed' will be selected by default.` },
            { text: `Bug Fix -Fixed issue of missing column 'Module' in the Equipment Excel Export.` },
        ]
    },

	{
        header: 'TAP App (6.02.11 to 12) July 29th, 2019',
        content: [
            { text: `Equipment List: Drawings - Provided option to move and delete the shapes after saving.` },
            { text: `Equipment List: Drawings - Provided option to edit the text after saving.` },
            { text: `Provided Test Date as mandatory (selected and disabled) while updating the Equipment Test result to Passed or Failed through Update Status.` },
            { text: `Update Status - Removed 'Update Test Date' check box from Update Status Window.` },
            { text: `Update Status - If Test Method 'None' is selected in the Update Status window, the option 'Not Tested' only will be displayed as selected in the Test Result. If any Test Date exists for the selected Equipment, then it will be cleared.` },
            { text: `Update Status - If Test Method other than 'None' is selected in the Update Status window, the options 'Passed' and 'Failed' will be displayed in the Test Result and 'Passed' will be selected by default.` }
        ]
    },

	{
        header: 'TAP App (6.02.10) July 1st, 2019',
        content: [
            { text: `New Equipment Type - 'Emergency Lighting' added.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.14) July 1st, 2019',
        content: [
            { text: `New Equipment Type - 'Emergency Lighting' added.` },
        ]
    },

	{
        header: 'TAP Reporting (6.01.14) July 1st, 2019',
        content: [
            { text: `Added limitations on deficiency list entries for NFPA80.` }
        ]
    },

	{
        header: 'TAP App (6.02.09) June 10th, 2019',
        content: [
            { text: `New Equipment Type - 'Emergency Communications' added.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.13) June 10th, 2019',
        content: [
            { text: `New Equipment Type - 'Emergency Communications' added.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.12) June 03rd, 2019',
        content: [
            { text: `Provided Site Full Name and Number in As Builts Drawings list Page.`},
            { text: `Sietap Technical Support emails replaced with message prompt "Contact your branch's TAP Admin or your Zone Technical Expert (ZTE) for assistance".` },
            { text: `Removed text 'Panel' from Equipment Data screen, while adding an Equipment Group. Replaced text 'Panel Type' with 'System Type' in System Add/Edit page.` },
            { text: `While adding Branch Administrators and Technicians, it is now mandatory to select atleast one Sales Office.` },
            { text: `Search enabled for Closed Sites.`},
            { text: `Branch Administrators and Technicians are not allowed to upload Test Results via Excel Import.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.11) May 26th, 2019',
        content: [
            { text: `TAP Web Parsing - Fixed issue with Alt Text Parsing.` },
        ]
    },

	{
        header: 'TAP App (6.02.08) May 12th, 2019',
        content: [
            { text: `New SSL Certificate pinned with apk.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.10) April 08th, 2019',
        content: [
            { text: `Drawings page is provided as the Default page for As Builts Module.` },
        ]
    },

	{
        header: 'TAP App (6.02.07) March 25th, 2019',
        content: [
            { text: `Markup feature added for Drawings.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.09) March 25th, 2019',
        content: [
            { text: `Provided prompt message to save the changes in Inspection Details: Questions, if switched from one node to another without saving the changes.` },
            { text: `Default Expiry Date of a New Customer is set with <Current Date>/<Current Month>/2050 and Default Status as Active.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.08) February 25th, 2019',
        content: [
            { text: `Option to control which Questions get pulled forward to a new task from last closed task.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.07) February 11th, 2019',
        content: [
            { text: `Lead Tech column added in Active / New Inspection Tasks and Recently Closed Inspection Tasks sections in Dashboard.` },
            { text: `Make/Model column provided in the Equipment list in Inspection Details while selecting "All" in Equipment Type.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.06) January 28th, 2019',
        content: [
            { text: `Equipment Testing Summary Report of an Inspection Task, based on NFPA/Health Classification are provided in Inspection Task list page.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.04 to 05) January 21st, 2019',
        content: [
            { text: `'Next' and 'Previous' options are provided in the Equipment Edit and Update Status screen in Inspection Details page for quick update of Equipment.` },
            { text: `Lead Tech name added as last column in Inspection Activity Report.` }
        ]
    },

	{
        header: 'TAP Bridge (6.01.11) December 10th, 2018',
        content: [
            { text: `Increase the stability of the system.` }
        ]
    },

	{
        header: 'TAP App (6.02.02) December 10th, 2018',
        content: [
            { text: `Increase the stability of connectivity with TAP Bridge.` },
            { text: `Add Tap App to "Apps Not Optimized" list for Android devices with Marshmallow and above.` },
        ]
    },

	{
        header: 'TAP Webv6 (6.02.03) December 03rd, 2018',
        content: [
            { text: `Comment field added in the Update Status page in Equipment Details.` },
            { text: `Blocked technicians to Delete a Drafted report attached with a final report.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.02) September 30th, 2018',
        content: [
            { text: `Combination of characters '&#' allowed in excel file import.` },
            { text: `Removed 'TJC Fire Alarm Panel Report' from Report Selection Pages.` }
        ]
    },

	{
        header: 'TAP Bridge (6.01.02) September 23rd, 2018',
        content: [
            { text: `Support for panel configuration parsing on TAP Web.` },
            { text: `More robust Desigo FS20 connectivity on both Windows 7 and 10.` }
        ]
    },

	{
        header: 'TAP App (5.1.54) September 23rd, 2018',
        content: [
            { text: `Panel Type FS20 is replaced with Desigo FS20 and added the Desigo FV20 panel type.` },
            { text: `Desigo Modular added to the Panel List.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.02.01) September 23rd, 2018',
        content: [
            { text: `Implemented XML Parsing when activating a new Task or reactivating an already existing Task with updated System XML file.` },
            { text: `Panel Type FS20 is replaced with Desigo FS20 and added the Desigo FV20 panel type.` },
            { text: `Desigo Modular added to the Panel List.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.33) September 11th, 2018',
        content: [
            { text: `Implemented Search by Notification Number.` },
            { text: `Add and Delete options of Site and Contract is restricted for Technician User Role.` },
            { text: `Modified the Move implementation to perform System Move to a Contract within the same Customer and Site.` },
            { text: `Following Characters, if exist in a report file name will be replaced with hyphen(-) while creating a drafted report.<br/>
			\ / : * ? " < > |` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.32) September 02nd, 2018',
        content: [
            { text: `Modified Drafted Reports columns with suggested changes.` },
            { text: `Drafted Report File Name while downloading is modified with suggested changes.` },
            { text: `Import from Excel file implementation is modified so as to stop the equipment import at 1st empty row in the excel.` },
            { text: `Location is made mandatory while importing Equipment.` },
            { text: `Restricted duplicate equipment addresses across Equipment Types.` },
            { text: `Bug Fix -Fix for sorting date column ["Uploaded On"] in Report Config page.` }
        ]
    },

	{
        header: 'TAP Reporting (6.00.56) September 02nd, 2018',
        content: [
            { text: `Remove TOC appearing on old documents.` },
            { text: `Create multiple tables with headings per SystemType with true element in table instance or definition.` },
            { text: `Table Cell Margin support.` }
        ]
    },

{
        header: 'TAP App (5.1.52 to 53) August 12th, 2018',
        content: [
            { text: `Retaining the equipment filter on changing the nodes.` },
            { text: `Performance improvement for Drawings.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.31) July 16th, 2018',
        content: [
            { text: `Updated the application with French, Spanish and German character support.` }
        ]
    },

	{
        header: 'TAP App (5.1.51) July 16th, 2018',
        content: [
            { text: `Updated the application with French, Spanish and German character support.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.29 to 30) June 11th, 2018',
        content: [
            { text: `Modified Upload and Download options in Report Templates / Report Configs to upload or download multiple Files.` },
            { text: `Indus to zLink changes. Support email changed to 'tap.support@zlinkcorp.com'` }
        ]
    },

	{
        header: 'TAP App (5.1.48 to 50) May 21st, 2018',
        content: [
            { text: `New Equipment Types - 'Fire Hoses' and 'Gaseous Extinguishing Systems' added.` },
            { text: `Indus to zLink change in login page.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.28) May 17th, 2018',
        content: [
            { text: `Inspection Tasks - 'Recent' filter in the Inspection Tasks page is modified to 'Last 3 months', 'Last 6 months' and 'Last 12 months', to list last 3 months tasks, last 6 months tasks and last 1 year' tasks respectively.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.27) May 14th, 2018',
        content: [
            { text: `New Equipment Types - 'Fire Hoses' and 'Gaseous Extinguishing Systems' added.` },
            { text: `Font in the Inspection Details is made consistent with the font selected in Inspection Tasks page.` },
            { text: `Modified the Links option in the dashboard to add links.` },
            { text: `Restricted the option of adding/modifying/deleting the Training Materials and links to Super Administrator only.` },
            { text: `Inspection Tasks page - Changed label 'All' to 'Both'.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.22 to 26) May 6th, 2018',
        content: [
            { text: `New UI for Dashboard with performance improvement` },
            { text: `New Options to upload/download Links / Training Materials in the Dashboard` },
            { text: `New UI for Inspection Tasks page<br/>
			- Provided All/Active/Closed radio button to filter the Tasks List.<br/>
			- Provided All/Recent drop down to filter the Recent Tasks.<br/>
			- Provided a Keyword Search for Inspection Task Name and System Name.<br/>
			- A font selector option - S, M, L is provided to choose Small, Medium and Large fonts respectively.` },
            { text: `Settings for 'Rows per Page for Inspection Tasks' and 'Show All' options are provided in Personal Settings.<br/>
			- Number of Tasks listing in the Inspection Tasks page is limited to 20 per page by default.` }
        ]
    },

	{
        header: 'TAP App (5.1.43 to 45) April 13th, 2018',
        content: [
            { text: `New SSL Certificate pinned with apk.` },
            { text: `Added New Equipment Types - Fire Pumps, Kitchen Extinguishers, Portable Extinguishers, Elevators and Fire Department Connections.` },
            { text: `Added filter option with wildcard search and location text to Assign Devices to a Block feature.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.21) April 13th, 2018',
        content: [
            { text: `New Equipment Type - 'Fire Department Connections' added.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.19 to 20) April 5th, 2018',
        content: [
            { text: `Added a "Passed" prompt after validating data in excel while importing equipment.` },
            { text: `Provided option to have Notification number that start with 5 and are 10 digits long for Canada offices.` },
            { text: `Inspection Task grid sorted by Planned Start Date as default.` },
            { text: `Added New Equipment Types - Fire Pumps, Kitchen Extinguishers, Portable Extinguishers and Elevators.` },
            { text: `To accommodate more Equipment Types, changed the radio buttons to drop down list in Display Settings, Data Entry Settings, Master Data and Inspection Details` }
        ]
    },

	{
        header: 'TAP App (5.1.41 to 42) March 26th, 2018',
        content: [
            { text: `Allowed Special Characters - " and ' in Location text.` },
            { text: `Provided Search text to find the text within the Drawing file.` },
            { text: `Added a Filter Option and location text to Assign Devices to a Block feature` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.17 to 18) March 25th, 2018',
        content: [
            { text: `Provided option to Copy Equipment in Equipment Details of Inspection Tasks` },
            { text: `Allowed Special Characters - " and ' in Location text during Excel Import.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.16) February 19th, 2018',
        content: [
            { text: `Provided option to Upload XML file by closing Inspection Task and re-activate the same after uploading the file` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.15) January 22nd, 2018',
        content: [
            { text: `Provided options to Add dynamic(editable) System and Contract inspection questions from the Web UI <br/>
			-Fire and Safety -> Setup -> Inspection Fields` },
            { text: `Provided option to mail log file while generating reports for Super Admin` },
            { text: `Provide Site, Building filter option in As Builts drawing page <br/>
			-As-Builts -> Drawings` },
            { text: `Filtered tree node display in Inspection Questions based on controls mapped to Reports` },
        ]
    },

	{
        header: 'TAP Webv6 (6.1.13) December 11th, 2017',
        content: [
            { text: `Added 'Max Characters' field for Text fields in Inspection Fields` }
        ]
    },

	{
        header: 'TAP App (5.1.40) November 11th, 2017',
        content: [
            { text: `TAP APP Support for Tablets` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.11 to 12) November 11th, 2017',
        content: [
            { text: `Provided option to Delete panels from System for Super Administrators<br/>
			-Fire and Safety -> Systems -> Delete -> System Panels/Unlinked Panels` },
            { text: `Provided option to Close and re-activate Inspection Task from System Edit` },
            { text: `'Delete Inactive Account' option provided in Password Policy` },
            { text: `Proxy upgraded with latest version of SignalR (2.2.2)` }
        ]
    },

	{
        header: 'TAP App (5.1.38) September 18th, 2017',
        content: [
            { text: `Support for FS20 Inspection in TAP App` },
            { text: `Provided option to switch to drawing from Equipment list` },
            { text: `Country vice display of equipment attributes` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.10) September 18th, 2017',
        content: [
            { text: `Provided option to Set and display Equipment attributes based on Country (Display Settings and Data Entry Settings)<br/>
			-Fire and Safety -> Setup -> Display Settings -> Common/Equipment Type Attributes
			-Fire and Safety -> Setup -> Data Entry Settings -> Common/Equipment Type Attributes` },
            { text: `Added 'Uploaded On' column in Report Templates and Report Configs pages <br/>-Fire and Safety -> Setup -> Reports -> Report Templates/Report Configs` },
            { text: `Added new field 'SPK License Number' in Branch Office` },
            { text: `Font style changed in easy list of Change customer and all Search pages` },
        
        ]
    },

	{
        header: 'TAP App (5.1.37) August 26th, 2017',
        content: [
            { text: `Drawing download on demand in TAP App` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.9) August 26th, 2017',
        content: [
            { text: `Provided support for Sprinkler inspection` },
            { text: `Provided 'Copy data to other panels' option per tree node with a drop down list of Panels to choose from in Inspection Details page` },
            { text: `Support panel inspection questions up to 150 characters` },
            { text: `New Report Mapping GUI <br/>
			-Fire and Safety -> Report Mapping` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.6 to 9) July 23rd, 2017',
        content: [
            { text: `UI modified to include Untested devices in Reports` },
            { text: `Restricted Contract Number starting with '51'` },
            { text: `Provided two new fields 'Dataset Name' and 'Data Field Name' in Inspection Fields Add/Edit` },
            { text: `Enabled two new inspection reports 'Test and Inspection Form H' and 'Test and Inspection Form I'` },
            { text: `Provided multiple download of 'Drafted Reports' and 'Final Reports' as ZIP file` },
            { text: `Provided option to upload/download Report Configuration files for Super Admin<br/>
			-Fire and Safety -> Setup -> Reports -> Report Configs` },
        ]
    },

	{
        header: 'TAP Reporting (6.00.15 to 18) July 20th, 2017',
        content: [
            { text: `Reporting DLL modified to handle the NFPA72_02 report` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.5) June 12th, 2017',
        content: [
            { text: `Added multiple search conditions in equipment list filter option<br/>
			Fire and Safety -> Inspection Tasks -> Inspection Details -> Search` }
        ]
    },

	{
        header: 'TAP Reporting (6.00.14) June 12th, 2017',
        content: [
            { text: `Added new EP25 report.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.4) June 12th, 2017',
        content: [
            { text: `Provided option to add/update Report Groups and Reports for Super Admin<br/>
			-Fire and Safety -> Setup -> Reports -> Report Groups<br/>
			-Fire and Safety -> Setup -> Reports -> Reports` },
            { text: `Provided Equipment Specific and Orphan tabs in Fire and Safety Drawing view grid list<br/>
			-Equipment Specific - User can filter and view the details of all assigned equipment belonging to a particular Equipment Type by clicking Equipment Specific tab.<br/>
			-Orphan - Orphan tab lists de-linked equipment which are de-linked from drawings as a result of<br/>
			**Replacement/revision of an existing drawing in As-Builts module.<br/>
			**Deletion of a drawing in As-Builts, and adding a new one for the same floor.` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.2) May 15th, 2017',
        content: [
            { text: `Drawing - Markup functionality implemented in Asbuilts module.` }
        ]
    },

	{
        header: 'TAP Reporting (6.00.09) May 6th, 2017',
        content: [
            { text: `Attempt to address issue of equipment not appearing in Canada report.` }
        ]
    },
	{
        header: 'TAP Webv6 (6.1.1) April 27th, 2017',
        content: [
            { text: `'Sales Office' column included in Administrative Reports -> Contracts` },
            { text: `Inspection Task list is the default page for Fire and Safety module` }
        ]
    },

	{
        header: 'TAP Webv6 (6.1.0) April 24th, 2017',
        content: [
            { text: `Removed TAP Bridge depedency to Generate Reports (Report Version: 6.00.05)` }
        ]
    },

	{
        header: 'TAP Webv6 (6.0.5) March 27th, 2017',
        content: [
            { text: `Branch Admin can access all customers of Country in which his/her Sales Office is linked` },
            { text: `TAP Web version number moved to Help page` },
            { text: `Restricted System Panel Type change, if there are any equipment exists in Inspection Task` },
            { text: `New User will be redirected to 'Change Customer' page on login` }
        ]
    },

	{
        header: 'TAP Webv6 (6.0.4) January 23rd, 2017',
        content: [
            { text: `Siemens user (Technicians and Branch Admins) are able to unlock their account by themselves if it's in Locked status` },
            { text: `Provided Close option in dashboard for Active tasks that are planned at least 3 months ago and older for Super Admin` },
            { text: `Provided quick link menu in Generate Report and Drafted Report pages to switch to Drafted Report and Generate Reports respectively` }
        ]
    },

	{
        header: 'TAP Webv6 (6.0.3) November 28th, 2016',
        content: [
            { text: `Release of HTML5 version of TAP Web` }
        ]
    },

	{
        header: 'TAP Webv6 (6.0.2) November 19th, 2016',
        content: [
            { text: `Provided Building and Floor selection in Import Equipment` }
        ]
    },

	{
        header: 'TAP Webv6 (6.0.1) October 31st, 2016',
        content: [
            { text: `Provided Country selection in Zone Add page to control national viewing of Customers and Technicians` },
            { text: `Provided TAP Bridge running status check in Generate Report page. If TAP Bridge is not running in Report Generation Mode, 'Start TAP Bridge in Report Generation Mode' message will be displayed` },
            { text: `Provided more Panel Types like Bosch, Edwards, Fike etc. in System Add/Edit pages` },
            { text: `Config File Upload and download options provided in System List page` },
            { text: `Implemented Panel, Building and System selection in Generate Reports page` },
            { text: `Provided Report Type column in Drafted Reports and Final Report pages` }
        ]
    },

	{
        header: 'TAP Webv6 (6.0.0) September 30th, 2016',
        content: [
            { text: `Provided Technician Details report under Administrative Reports<br/>
			<font color = "#007979">-Fire and Safety </font>> Administrative Reports > Technician Details` },
            { text: `Automatically created Modules show a Location text of 'Auto Created' and NFPA Classification to be 'Choose a Classification'` },
            { text: `On clicking the Inspection Task from the Dashboard, if the Status is New, it will be redirected to the Edit window instead of Inspection Details page` },
            { text: `Added the building name to the Inspection Result in the SRT file when a building name has been associated with a device` },
            { text: `Provided radio buttons to Horizontal and vertical alignment of Inspection Fields on Edit` },
            { text: `Modified the equipment list export to separate the Panel, Module and Device` },
			{ text: `Technician Required field name changed to Tech Required at panel; Technician Count field name changed to Qty of Techs in Inspection task` },
            { text: `Copy Test Result option provided while activating an inspection task to include previous test results on creating new Inspection Task` },
            { text: `Export option implemented in Inspection Task 'Compare'` },
            { text: `Drafted report updating condition modified to insertion` },
			{ text: `Removed Deleted users in Admin by Banch report` },
            { text: `Download Drafted report icon provided in Drafted report grid in mouse over` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.20) June 23rd, 2016',
        content: [
            { text: `Provided the Panel Type 'FS20' in System Add/Edit pages` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.19) May 12th, 2016',
        content: [
            { text: `Provided the field 'Notification Number' in Inspection Task Add/Edit for Contract No. starting with 5` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.18) May 09th, 2016',
        content: [
            { text: `Upload File options in Systems page is modified to accept ZIP files also with content of the ZIP file should be valid xml file(s)` },
            { text: `Text displayed in the System Add/Edit Page and Inspection Details pages are modified (HNET for testing one XLS node at a time and XNET for testing multi-node XLS systems)` },
            { text: `Task History Filter and Inspection Task selection options provided in Report Generation page` },
            { text: `Changed text format in Help` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.17) May 02nd, 2016',
        content: [
            { text: `Modified the email content when users other than 'Active' users use Forgot Password option. Provided Branch Administrator's name for Technicians and Anthony's name for Branch Administrator's` },
            { text: `User role included in User Activity Report <br/>
			-Fire and Safety > Reports > General Reports > User Activity Report` },
            { text: `Provided new report named Branch Admin Report which helps the user to view Branch Admins of each Sales Office<br/>
			-Fire and Safety > Reports > General Reports > Branch Admin Report` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.16) April 29th, 2016',
        content: [
            { text: `Provided SSL Cerificate migration support in TAP App` }
        ]
    },

	{
        header: 'TAP Webv5 ( 5.1.15) April 13th, 2016',
        content: [
            { text: `Provided scheduler application to lock user as per the password policy settings` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.18) April 07th, 2016',
        content: [
            { text: `Provided a report to view user activity <br/>
			-Fire and Safety > Reports > General Reports > User Activity Report` },
            { text: `Provided pattern validation in Customer Number, Site Number, Contract Number and Notification Number fields` },
            { text: `Notification Number field made visible only for Contract Number starting with 26` },
            { text: `Dev Reading, Dev Reading Date and Note are removed from multiple device 'Update Status' option` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.13) March 30th, 2016',
        content: [
            { text: `Provided an option in Inspection task list page to make a Task Inactive` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.10) March 04th, 2016',
        content: [
            { text: `Provided Building & Floor in Multiple Edit option of Equipment` },
            { text: `Name change in SRT Import menu (SRT Excel Migration to SRT Migration)` },
            { text: `Ignore Inspection Results should only avoid Test Result. All Equipment will be imported` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.9) February 26th, 2016',
        content: [
            { text: `Dev. Reading available in Multiple edit` },
			{ text: `Bug Fix -Fix on showing 'Tech Advance+ encountered a problem while executing your command' on accessing inspection Task list -> Sort on re-login after a session time out.` },
			{ text: `Bug Fix -Fix on TAP Bridge showing incorrect connection count while Quitting job from Web.` },
            { text: `Bug Fix -Fix on Connectivity Issue` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.8) February 17th, 2016',
        content: [
            { text: `Display meaningful warning messages for special characters which cause security threat<br/>
			-Special character < character > is not allowed in < Fieldname >` },
            { text: `Show the Selected Date Range in Report heading` },
            { text: `Provide a new column Panel Type adjacent to System Name in Inspection Activity Details Report` },
            { text: `Branch Administrators to have permission to the following menu options in Documents module<br/>
			-Documents > Setup > General > Document Category<br/>
			-Documents > Setup > Access` },
            { text: `Limit Panel Address to maximum 3 digits in the following area<br/>
			-Add / Edit Panel<br/>
			-SRT import` },
            { text: `Bug Fix -Fix issue with Barcode values got deleted when Barcode field is removed from Data entry pages` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.7) February 01st, 2016',
        content: [
            { text: `Remove the option TAp Bridge with Python from plugins page.` },
            { text: `Provide Quit Job option to Branch Admin.` },
            { text: `Position Alarm Signal above Alarm Restore.` },
            { text: `Remove Trailing/Leading spaces in NodeId.` },
            { text: `Allow the Enter key to be the same as the Select button in Customer Selection page.` },
            { text: `Bug Fix -Fix issue with value got truncated in Special Procedures and General Notes.` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.6) February 01st, 2016',
        content: [
            { text: `Bug Fix -Fix issue with Large XML import.` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.5) January 15th, 2016',
        content: [
            { text: `Set Contract Name, Site Name & Customer Name as mandatory fields.` },
            { text: `Bug Fix -Fix issue with SRT Import - Junk values got inserted in Inspection fields.` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.4) December 17th, 2015',
        content: [
            { text: `Compare option to compare equipment list from one Inspection Task to another, report on the equipment added/updated and deleted <br/>
			-Fire and Safety > Inspection > Inspection Tasks > Inspection Task > Compare` },
            { text: `Search Text option to search for a text inside the drawing.<br/>
			-Fire and Safety > Inspection > Inspection Tasks > Drawing > Tools > Search Text` }
        ]
    },

	{
        header: 'TAP Webv5 (5.1.3) December 17th, 2015',
        content: [
            { text: `Global search option for Sites, Contracts, Systems and Inspection Tasks.<br/>
			-Fire and Safety > Search` },
            { text: `Option to Import SRT file against an Inspection Task.<br/>
			-Fire and Safety > Inspection > Inspection Tasks > Inspection Task > Import SRT` },
            { text: `Global reports on Contracts, Inspection Tasks, Inspection Task Activity and Final Reports.<br/>
			-Fire and Safety > Reports > General Reports` },
            { text: `New columns Site,System,Contract & Inspection Task has been added to the logbook.` },
        ]
    },

	{
        header: 'TAP Proxy December 03rd, 2015',
        content: [
            { text: `New API GetEquipmentListProcessingIndicator Added in Proxy - to show progress of processing equipment list` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.2) November 03rd, 2015',
        content: [
            { text: `Page Modifications in Web for Canada.` },
            { text: `Page Modifications in SRT and Excel Import for Canada.` },
            { text: `Remove MKB5 Keyboard checkbox from System Add/Edit, Grid from Inspection Systems.` },
            { text: `Add two more Radio buttons * HNET; for single node XLS, or a multi-node XLS with only one GPMI, XNET; for a multi-node XLS with at least two GPMI with control, or a Management Station (NCC or DCC) in System and Inspection Systems for XLS type system (If the system is networked).` },
            { text: `Included Report Title in CSV import and Report Selection Pages.` },
            { text: `Add the ability to identify a layer.` },
			{ text: `Removed email domain validation of Service Organization email field.` },
            { text: `Added a search option to find a device Address in the equipment list of Drawing grids.` },
            { text: `Include Assign Block option in drawing menu.` },
            { text: `The grid pointer should remain in the same record after the linking is complete.` },
			{ text: `Show Last selected Site and Contract in System /Inspection task /Inspection Report & Final Report page.` },
            { text: `Provide Release Note link in TAP Web with details on each version release.` },
            { text: `Show 'Update' button in Report Selected option in Inspection Details page.` },
            { text: `Bug Fix -Fixed issue with sales doc number (Contract details) not displaying correctly in Report.` },
        ]
    },

	{
        header: 'TAP Webv5 (5.1.1) October 26th, 2015',
        content: [
            { text: `Return Country Code US/CA with respect to the Inspection Task for the available inspections and when running the reports.` },
            { text: `Modified the version number to 5.1.1` },
            { text: `TAP Proxy also modified to return Country Code US/CA with respect to the Inspection Task for the available inspections and when running the reports.` },
        ]
    },

	{
        header: 'TAP Webv5 ( 5.1.0) October 09th, 2015',
        content: [
            { text: `Bug Fix -Fix of Module Edit, Display of Customer Name field.` },
        ]
    },

	{
        header: 'TAP Webv5 ( 5.1.0) October 05th, 2015',
        content: [
            { text: `Input screen adjustment of Inspection task Add/Edit page.` },
            { text: `Input screen to use single lines on Address field in Customer Add/Edit page.` },
            { text: `Bug Fix -Security fixes and related functionality fixes.` },
        ]
    },
];
